export function calculateFixedPremium(premium, orderQuantity) {
  if (premium.type != "fixed") {
    return "N/A";
  }
  if (premium.total_or_unit === "total") {
    return premium.base_premium;
  }
  if (premium.total_or_unit === "unit") {
    return premium.base_premium * orderQuantity;
  }
  return premium.base_premium;
}

export function validateAllocationAgainstPremium(premium, allocation) {
  // clone the premium.validation_records into allocation.validation_records
  // pull the records for the allocation crop
  // for each validation record in allocation.validation_records
  // if validation_record.payload.numberRequired === "One" and the allocation crop records contain a record that matches the validation record type add requirements_met: true to the allocation.validation_records record
  // if validation_record.payload.numberRequired === "Multiple" and the allocation crop records contain greater than one record that matches the validation record type add requirements_met: true to the allocation.validation_records record
  // else add requirements_met: false to the allocation.validation_records record
  // return the allocation
  let allo = { ...allocation };
  allo.validation_records = premium.validation_records.map((record) => {
    let requirements_met = false;
    let matchingRecords = allo.crop_records?.filter(
      (cropRecord) => cropRecord.category == record.category,
    );
    if (record.payload.numberRequired === "One") {
      if (matchingRecords && matchingRecords.length > 0) {
        requirements_met = true;
      }
    }
    if (record.payload.numberRequired === "Multiple") {
      if (matchingRecords && matchingRecords.length > 1) {
        requirements_met = true;
      }
    }
    return { ...record, requirements_met };
  });
  // if all premium_requirements_met are true, add a requirements_met: true to the allocation
  allo.premium_requirements_met = allo.validation_records.every(
    (record) => record.requirements_met,
  );
  allo.number_of_requirements_met = allo.validation_records.filter(
    (record) => record.requirements_met,
  ).length;

  return allo;
}

export function aggregateValidatedPremiumData(validatedPremiums) {
  return {
    // when calculating the total value, only include the value of premiums that are fixed
    totalValue: validatedPremiums
      .reduce(
        (acc, premium) => acc + (premium.type === "fixed" ? premium.value : 0),
        0,
      )
      .toFixed(2),
    // sum of value where allocations_met === allocations.length
    completedValue: validatedPremiums
      .reduce(
        (acc, premium) =>
          acc +
          (premium.allocations_met === premium.allocations.length
            ? premium.value
            : 0),
        0,
      )
      .toFixed(2),
    // count of premiums in validatedPremiums
    totalPremiums: validatedPremiums.length,
    // count of premiums where allocations_met === allocations.length
    completedPremiums: validatedPremiums.filter(
      (premium) => premium.allocations_met === premium.allocations.length,
    ).length,
    // non-fixed premiums - the number of premiums without a fixed value
    nonFixedPremiums: validatedPremiums.filter(
      (premium) => premium.type !== "fixed",
    ).length,
  };
}
