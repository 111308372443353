<template>
  <delete-modal
    :open="modalIsActive"
    modalTitle="Delete Field"
    modalDescription="Are you sure you want to permanently delete this record?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="deleteRecord"
  />
  <form @submit.prevent="submitForm" id="testForm">
    <page-card headingTitle="TEST ALL FORM FIELD COMPONENTS">
      <template #buttonArea>
        <submit-button
          buttonText="Save"
          :marginLeft="false"
          :errorArray="errorArray"
          formId="testForm"
        />
        <base-button
          buttonText="Delete"
          :redButton="true"
          @buttonClick="deleteRecord"
        />
      </template>
      <template #cardDetails>
        <div class="col-span-6">
          <test-table />
        </div>
        <list-select
          inputTitle="list
        selection"
          :wideItem="wideItem"
          :displayOnly="displayOnly"
          :placeholder="placeholder"
          :listArray="listArray"
          help-text="this is how
        you can use this <i>checkbox</i>"
        />

        <checkbox-input
          inputTitle="checkbox"
          :wideItem="wideItem"
          :displayOnly="displayOnly"
          :placeholder="placeholder"
          v-model="checkboxAttr"
          help-text="this is how you can use this <i>checkbox</i>"
        />
        <styled-input
          inputTitle="styled input"
          :wideItem="wideItem"
          :required="required"
          :displayOnly="displayOnly"
          :placeholder="placeholder"
          v-model="textAttr"
          helpText="this is how you can use this <i>component</i> <p> and this is a paragraph</p>"
        />
        <combo-box
          inputTitle="combo box"
          :wideItem="wideItem"
          :required="required"
          :displayOnly="displayOnly"
          displayOnlyValue="Display Only Value Text"
          :placeholder="placeholder"
          :selectOptions="optionList"
          v-model="comboAttr"
          helpText="this is how you can use this <i>component</i> <p> and this is a paragraph</p>"
          helpTitle="this is a help title"
        />
        <currency-input
          inputTitle="currency"
          :wideItem="wideItem"
          :required="required"
          :displayOnly="displayOnly"
          :displayOnlyValue="1234.56"
          :placeholder="placeholder"
          v-model="currencyAttr"
          helpText="this is how you can use this <i>component</i> <p> and this is a paragraph</p>"
          helpTitle="this is a help title"
        />
        <date-input
          inputTitle="date"
          :wideItem="wideItem"
          :required="required"
          :displayOnly="displayOnly"
          displayOnlyValue="2022-12-22"
          :placeholder="placeholder"
          v-model="dateAttr"
          helpText="this is how you can use this <i>component</i> <p> and this is a paragraph</p>"
          helpTitle="this is a help title"
        />
        <date-time-input
          inputTitle="date time"
          :wideItem="wideItem"
          :required="required"
          :displayOnly="displayOnly"
          displayOnlyValue="2022-12-22T13:16:02"
          :step="1"
          :placeholder="placeholder"
          v-model="dateTimeAttr"
          helpText="this is how you can use this <i>component</i> <p> and this is a paragraph</p>"
          helpTitle="this is a help title"
        />
        <email-input
          inputTitle="email"
          :wideItem="wideItem"
          :required="required"
          :displayOnly="displayOnly"
          :placeholder="placeholder"
          v-model="emailAttr"
          helpText="this is how you can use this <i>component</i> <p> and this is a paragraph</p>"
          helpTitle="this is a help title"
        />
        <phone-input
          inputTitle="phone"
          :wideItem="wideItem"
          :required="required"
          :displayOnly="displayOnly"
          :placeholder="placeholder"
          v-model="phoneAttr"
          helpText="this is how you can use this <i>component</i> <p> and this is a paragraph</p>"
          helpTitle="this is a help title"
        />
        <phone-input-2
          inputTitle="phone 2"
          :wideItem="wideItem"
          :required="required"
          :displayOnly="displayOnly"
          :placeholder="placeholder"
          v-model="phoneAttr2"
          helpText="this is how you can use this <i>component</i> <p> and this is a paragraph</p>"
          helpTitle="this is a help title"
        />
        <number-input
          inputTitle="number"
          :wideItem="wideItem"
          :required="required"
          :displayOnly="displayOnly"
          :displayOnlyValue="12345"
          :placeholder="placeholder"
          v-model="numberAttr"
          helpText="this is how you can use this <i>component</i> <p> and this is a paragraph</p>"
          helpTitle="this is a help title"
        />
        <radio-input
          inputTitle="radio buttons"
          :wideItem="wideItem"
          :required="required"
          :displayOnly="displayOnly"
          :placeholder="placeholder"
          v-model="radioAttr"
          :selectOptions="[
            { label: 'Option 1', value: '1' },
            { label: 'Option 2', value: '2' },
          ]"
          helpText="this is how you can use this <i>component</i> <p> and this is a paragraph</p>"
          helpTitle="this is a help title"
        />
        <select-dropdown
          inputTitle="select list"
          :wideItem="wideItem"
          :required="required"
          :displayOnly="displayOnly"
          displayOnlyValue="display only value"
          :placeholder="placeholder"
          :selectOptions="['Option 1', 'Option 2']"
          v-model="selectAttr"
          helpText="this is how you can use this <i>component</i> <p> and this is a paragraph</p>"
          helpTitle="this is a help title"
        />
        <select-dropdown
          inputTitle="select list w/ ids"
          :wideItem="wideItem"
          :required="required"
          :displayOnly="displayOnly"
          displayOnlyValue="1"
          :placeholder="placeholder"
          :selectOptions="[
            { label: 'Option 1', value: '1' },
            { label: 'Option 2', value: '2' },
          ]"
          v-model="selectIdAttr"
          helpText="this is how you can use this <i>component</i> <p> and this is a paragraph</p>"
          helpTitle="this is a help title"
        />
        <select-search
          inputTitle="select search id"
          :wideItem="wideItem"
          :required="required"
          :displayOnly="displayOnly"
          displayOnlyValue="1"
          :placeholder="placeholder"
          :selectOptions="[
            { label: 'Option 1', value: '1' },
            { label: 'Option 2', value: '2' },
            { label: 'Option 3', value: '3' },
          ]"
          v-model="selectSearchIdAttr"
          helpText="this is how you can use this <i>component</i> <p> and this is a paragraph</p>"
          helpTitle="this is a help title"
        />
        <select-search
          inputTitle="select search"
          :wideItem="wideItem"
          :required="required"
          :displayOnly="displayOnly"
          displayOnlyValue="Option 3"
          :placeholder="placeholder"
          :selectOptions="['Option 1', 'Option 2', 'Option 3']"
          v-model="selectSearchAttr"
          helpText="this is how you can use this <i>component</i> <p> and this is a paragraph</p>"
          helpTitle="this is a help title"
        />
        <text-area
          inputTitle="text area"
          :wideItem="wideItem"
          :required="required"
          :displayOnly="displayOnly"
          displayOnlyValue="display only value text"
          :placeholder="placeholder"
          v-model="textAreaAttr"
          helpText="this is how you can use this <i>component</i> <p> and this is a paragraph</p>"
          helpTitle="this is a help title"
        />
        <text-input
          inputTitle="text"
          :wideItem="wideItem"
          :required="required"
          :displayOnly="displayOnly"
          displayOnlyValue="display only value text"
          :placeholder="placeholder"
          v-model="textAttr"
          helpText="this is how you can use this <i>component</i> <p> and this is a paragraph</p>"
          helpTitle="this is a help title"
        />
        <time-input
          inputTitle="time"
          :wideItem="wideItem"
          :required="required"
          :displayOnly="displayOnly"
          displayOnlyValue="13:14:15"
          :placeholder="placeholder"
          v-model="timeAttr"
          helpText="this is how you can use this <i>component</i> <p> and this is a paragraph</p>"
          helpTitle="this is a help title"
        />
        <variable-string-type-input
          inputTitle="variable text"
          :wideItem="wideItem"
          :required="required"
          :displayOnly="displayOnly"
          :displayOnlyValue="displayOnlyValueText"
          :placeholder="placeholder"
          v-model="variableAttr"
          helpText="this is how you can use this <i>component</i> <p> and this is a paragraph</p>"
          helpTitle="this is a help title"
        />
        <checkbox-input
          inputTitle="Display Only"
          :wideItem="wideItem"
          :placeholder="placeholder"
          v-model="displayOnly"
        />
        <checkbox-input
          inputTitle="Wide Item"
          :wideItem="wideItem"
          :placeholder="placeholder"
          v-model="wideItem"
        />
        <checkbox-input
          inputTitle="Required"
          :wideItem="wideItem"
          :placeholder="placeholder"
          v-model="required"
        />
        <text-input
          inputTitle="Text Input"
          :wideItem="true"
          :placeholder="placeholder"
          v-model="placeholder"
        />
        <rich-text-input
          inputTitle="Rich Text"
          :wideItem="true"
          :placeholder="placeholder"
          v-model="richTextAttr"
        />
        <base-button
          buttonText="Sanitize"
          :redButton="true"
          @buttonClick="saveRichText"
        />
        <text-area
          inputTitle="safe html"
          :wideItem="wideItem"
          :displayOnly="true"
          :displayOnlyValue="richTextSanitized"
        />
        <div v-html="richTextSanitized" class="prose"></div>
      </template>
    </page-card>
  </form>
</template>
<script>
import PageCard from "../cards/PageCard.vue";
import StyledInput from "./StyledInput.vue";
import CheckboxInput from "./CheckboxInput.vue";
import ComboBox from "./ComboBox.vue";
import SelectSearch from "./SelectSearch.vue";
import CurrencyInput from "./CurrencyInput.vue";
import DateInput from "./DateInput.vue";
import DateTimeInput from "./DateTimeInput.vue";
import EmailInput from "./EmailInput.vue";
import NumberInput from "./NumberInput.vue";
import PhoneInput from "./PhoneInput.vue";
import PhoneInput2 from "./PhoneInput2.vue";
import RadioInput from "./RadioInput.vue";
import RichTextInput from "./RichTextInput.vue";
import SelectDropdown from "./SelectDropdown.vue";
import TextArea from "./TextArea.vue";
import TextInput from "./TextInput.vue";
import TimeInput from "./TimeInput.vue";
import VariableStringTypeInput from "./VariableStringTypeInput.vue";
import ListSelect from "./ListSelect.vue";
import DeleteModal from "../modals/DeleteModal.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BaseButton from "../buttons/BaseButton.vue";
import client from "@/api/parcel/api.js";
import testTable from "./testTable.vue";
export default {
  components: {
    testTable,
    PageCard,
    CheckboxInput,
    ComboBox,
    SelectSearch,
    StyledInput,
    CurrencyInput,
    DateInput,
    DateTimeInput,
    EmailInput,
    ListSelect,
    NumberInput,
    PhoneInput,
    PhoneInput2,
    RadioInput,
    RichTextInput,
    SelectDropdown,
    TextArea,
    TextInput,
    TimeInput,
    VariableStringTypeInput,
    DeleteModal,
    SubmitButton,
    BaseButton,
  },
  data() {
    return {
      required: false,
      displayOnly: false,
      wideItem: true,
      placeholder: null,
      modalIsActive: false,
      errorArray: new Array(),
      checkboxAttr: null,
      comboAttr: null,
      currencyAttr: null,
      dateAttr: null,
      dateTimeAttr: null,
      emailAttr: "user@dummy.com",
      numberAttr: null,
      phoneAttr: null,
      phoneAttr2: null,
      radioAttr: null,
      selectAttr: null,
      selectIdAttr: null,
      selectSearchIdAttr: null,
      selectSearchAttr: null,
      textAreaAttr: null,
      textAttr: null,
      timeAttr: null,
      variableAttr: null,
      richTextAttr: `this is some <b> bold text</b><script>alert()<\/script> in the rich text editor`, // eslint-disable-line
      displayOnlyValueText: "display only value",
      displayOnlyValueDate: "7/13/2022, 3:16:02 AM",
      displayOnlyValueNumber: 1234567890,
      richTextSanitized: null,
      listArray: [
        { label: "option 1", selected: false },
        { label: "option 2", selected: false },
        { label: "option 3", selected: false },
        { label: "option 4", selected: false },
        { label: "option 5", selected: false },
        { label: "option 6", selected: false },
        { label: "option 7", selected: false },
      ],
    };
  },
  computed: {
    optionList() {
      return ["option 1", "option 2"];
    },
  },
  methods: {
    cancelDelete() {
      console.log("cancel delete");
    },
    deleteRecord() {
      console.log("delete record");
    },
    submitForm() {
      console.log("submitted form");
    },
    async saveRichText() {
      console.log("SAVE", this.richTextAttr);
      const data = await client.richtext.save(null, {
        body: this.richTextAttr,
      });
      this.richTextSanitized = data.body;
    },
    async loadRichText() {
      const data = await client.richtext.load();
      this.richTextSanitized = data?.body;
    },
  },
  mounted() {
    // this.loadRichText();
  },
};
</script>
