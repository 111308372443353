// Produce Market - Core
import BuyerPortalFrame from "@/pages/buyer-portal/BuyerPortalFrame.vue";
import BuyerPortalHome from "@/pages/buyer-portal/BuyerPortalHome";
import BuyerPortalHelp from "@/pages/buyer-portal/BuyerPortalHelp.vue"; // Buyer Portal

// --- Listings ---

// Public Buyer

// --- BP-Common ---
import BuyerChatThread from "@/pages/buyer-portal/ChatThread.vue";
// import HaulingReservationsAll from "@/pages/buyer-portal/hauling/HaulingReservationsAll.vue";
// import RecordsTable from "@/pages/buyer-portal/activity/RecordsTable.vue";

// BP-Affiliate
import AffiliateReport from "@/pages/buyer-portal/bp-affiliate/reporting/AffiliateReport.vue";
import AllAffiliateGrowers from "@/pages/buyer-portal/bp-affiliate/growers/AllAffiliateGrowers.vue";
import BuyerMarketOriginators from "@/pages/buyer-portal/bp-affiliate/originators/BuyerMarketOriginators.vue";
import EditOriginator from "@/pages/buyer-portal/bp-affiliate/originators/EditOriginator.vue";
import AllAffiliatePremiums from "@/pages/buyer-portal/bp-affiliate/premiums/AllAffiliatePremiums.vue";
import AffiliateGrowerReport from "@/pages/buyer-portal/bp-affiliate/growers/AffiliateGrowerReport.vue";
import AffiliateFieldReport from "@/pages/buyer-portal/bp-affiliate/growers/AffiliateFieldReport.vue";
import BuyerListingsSingle from "@/pages/buyer-portal/bp-affiliate/listings/BuyerListingsSingle.vue";
import BuyerGrapeListingsTable from "@/pages/buyer-portal/bp-affiliate/listings/BuyerGrapeListingsTable.vue";
import BuyerProduceListingsTable from "@/pages/buyer-portal/bp-affiliate/listings/BuyerProduceListingsTable.vue";
import BuyerListingsTable from "@/pages/buyer-portal/bp-affiliate/listings/BuyerListingsTable.vue";
import BuyerMarketOffers from "@/pages/buyer-portal/bp-affiliate/offers/BuyerMarketOffers.vue";
import ViewOffer from "@/pages/buyer-portal/bp-affiliate/offers/ViewOffer.vue";
import BuyerOrdersSingleOrder from "@/pages/buyer-portal/bp-affiliate/orders/BuyerOrdersSingleOrder.vue";
import ViewCropOrder from "@/pages/buyer-portal/bp-affiliate/orders/ViewCropOrder.vue";
import ViewCropDelivery from "@/pages/buyer-portal/bp-affiliate/orders/ViewCropDelivery.vue";
import BuyerOrderBook from "@/pages/buyer-portal/bp-affiliate/orders/OrderBook.vue";
import BuyerOrders from "@/pages/buyer-portal/bp-affiliate/orders/OrdersTable.vue";
import VendorsTable from "@/pages/buyer-portal/bp-affiliate/vendors/VendorsTable.vue";
import NewVendor from "@/pages/buyer-portal/bp-affiliate/vendors/NewVendor.vue";
import EditVendor from "@/pages/buyer-portal/bp-affiliate/vendors/EditVendor.vue";
import BuyerInquiries from "@/pages/buyer-portal/bp-affiliate/orders/InquiriesTable.vue";
import BuyerInquiriesSingle from "@/pages/buyer-portal/bp-affiliate/orders/BuyerInquiriesSingle";
import AffiliateCropSupply from "@/pages/buyer-portal/bp-affiliate/crop-supply/AffiliateCropSupply.vue";

// BP-Controller
import ControllerReport from "@/pages/buyer-portal/bp-controller/reporting/ControllerReport.vue";
import AllControllerGrowers from "@/pages/buyer-portal/bp-controller/growers/AllControllerGrowers.vue";
import ControllerContracts from "@/pages/buyer-portal/bp-controller/contracts/ControllerContracts.vue";
import NewContract from "@/pages/buyer-portal/bp-controller/contracts/NewContract.vue";
import EditContract from "@/pages/buyer-portal/bp-controller/contracts/EditContract.vue";
import ControllerAssignmentsTable from "@/pages/buyer-portal/bp-controller/assignments/ControllerAssignmentsTable.vue";
import NewAssignment from "@/pages/buyer-portal/bp-controller/assignments/NewAssignment.vue";
import EditAssignment from "@/pages/buyer-portal/bp-controller/assignments/EditAssignment.vue";
import BuyerMarketAffiliates from "@/pages/buyer-portal/bp-controller/affiliates/BuyerMarketAffiliates.vue";
import NewAffiliate from "@/pages/buyer-portal/bp-controller/affiliates/NewAffiliate.vue";
import ContractReport from "@/pages/buyer-portal/bp-controller/reporting/ContractReport.vue";
import AllControllerPremiums from "@/pages/buyer-portal/bp-controller/premiums/AllControllerPremiums.vue";
import ControllerGrowerReport from "@/pages/buyer-portal/bp-controller/growers/ControllerGrowerReport.vue";
import ControllerFieldReport from "@/pages/buyer-portal/bp-controller/growers/ControllerFieldReport.vue";
import ControllerInquiriesSingle from "@/pages/buyer-portal/bp-controller/orders/ControllerInquiriesSingle.vue";
import ViewControllerCropOrder from "@/pages/buyer-portal/bp-controller/orders/ViewControllerCropOrder.vue";
import ViewControllerCropDelivery from "@/pages/buyer-portal/bp-controller/orders/ViewControllerCropDelivery.vue";
import ControllerOrders from "@/pages/buyer-portal/bp-controller/orders/ControllerOrdersTable.vue";
import ControllerInquiries from "@/pages/buyer-portal/bp-controller/orders/ControllerInquiriesTable.vue";

//Settings
import SettingsAll from "@/pages/buyer-portal/settings/SettingsAll";
import SettingsSingleUser from "@/pages/buyer-portal/settings/SettingsSingleUser";
import SettingsSingleExternalOrg from "@/pages/buyer-portal/settings/SettingsSingleExternalOrg";
import SharedOrgs from "@/pages/auth/SharedOrgs.vue";

export default [
  {
    path: "/buyer",
    component: BuyerPortalFrame,
    name: "buyer",
    redirect: "/buyer/home",
    meta: {
      requiresAuth: true,
      requiredOrgFlag: "purchases_flag",
      //storeGetActions: ["getBuyerPortalDataOnAppStart"],
    },
    children: [
      // shared
      {
        path: "home",
        component: BuyerPortalHome,
        name: "buyer-portal-home",
        meta: {
          auth: true,
          title: "App Home | Parcel",
          displayTitle: "Home",
        },
      },
      {
        path: "help",
        component: BuyerPortalHelp,
        name: "buyer-help",
        meta: {
          auth: true,
          title: "Help | Parcel",
          displayTitle: "Help",
        },
      },
      {
        path: "settings",
        component: SettingsAll,
        name: "buyer-settings",
        meta: {
          auth: true,
          title: "Settings | Parcel",
          displayTitle: "Settings",
        },
      },
      {
        path: "settings/user",
        component: SettingsSingleUser,
        name: "buyer-settings-new-user",
        meta: {
          auth: true,
          admin: true,
          title: "Add User | Parcel",
          displayTitle: "Add User",
        },
      },
      {
        path: "settings/user/:userId",
        component: SettingsSingleUser,
        name: "buyer-single-user",
        meta: {
          auth: true,
          admin: true,
          title: "View User | Parcel",
          displayTitle: "View User",
        },
      },
      {
        path: "settings/external-org",
        component: SettingsSingleExternalOrg,
        name: "buyer-settings-new-external-org",
        meta: {
          auth: true,
          title: "Manage External Orgs | Parcel",
          displayTitle: "Manage External Orgs",
        },
      },
      {
        path: "switch-org",
        component: SharedOrgs,
        name: "buyer-switch-org",
        meta: {
          auth: true,
          title: "Switch Org | Parcel",
        },
      },
      // BP-Affiliate
      {
        path: "listings",
        component: BuyerListingsTable,
        name: "buyer-listings",
        meta: {
          title: "Listings | Parcel",
          storeGetActions: ["getBuyerListings"],
        },
      },
      {
        path: "produce-listings",
        component: BuyerProduceListingsTable,
        name: "buyer-listings-produce",
        meta: {
          title: "Produces | Parcel",
          storeGetActions: ["getBuyerListings"],
        },
      },
      {
        path: "produce-listings/listing/:listingId",
        component: BuyerListingsSingle,
        name: "buyer-produce-listings-single",
        meta: {
          title: "Listing | Parcel",
        },
      },
      {
        path: "grapes",
        component: BuyerGrapeListingsTable,
        name: "buyer-listings-grape",
        meta: {
          title: "Grapes | Parcel",
          storeGetActions: ["getBuyerListings"],
        },
      },
      {
        path: "grapes/listing/:listingId",
        component: BuyerListingsSingle,
        name: "buyer-listings-single",
        meta: {
          title: "Listing | Parcel",
        },
      },
      {
        path: "order-book",
        component: BuyerOrderBook,
        name: "buyer-order-book",
        meta: {
          title: "Orders | Parcel",
          storeGetActions: [
            "getBuyerCropOrders",
            "getBuyerAllocations",
            "getSharedRecords",
            "getSharedTasks",
          ],
        },
      },
      {
        path: "crop-supply",
        component: AffiliateCropSupply,
        name: "affiliate-crop-supply",
        meta: {
          title: "Crop Supply | Parcel",
          requiredFeatureFlag: "bp-affiliate",
        },
      },
      {
        path: "affiliate-reporting",
        component: AffiliateReport,
        name: "buyer-bp-affiliate-reporting",
        meta: {
          title: "Reporting | Parcel",
          requiredFeatureFlag: "bp-affiliate",
          storeGetActions: [],
        },
      },
      {
        path: "affiliate-growers",
        component: AllAffiliateGrowers,
        name: "buyer-bp-affiliate-growers",
        meta: {
          title: "Growers | Parcel",
          requiredFeatureFlag: "bp-affiliate",
          storeGetActions: [],
        },
      },
      {
        path: "affiliate-premiums",
        component: AllAffiliatePremiums,
        name: "buyer-bp-affiliate-premiums",
        meta: {
          title: "Premiums | Parcel",
          requiredFeatureFlag: "bp-affiliate",
          storeGetActions: [],
        },
      },
      {
        path: "affiliate-grower-report/:growerId",
        component: AffiliateGrowerReport,
        name: "buyer-bp-affiliate-grower-report",
        meta: {
          title: "Grower Report | Parcel",
          requiredFeatureFlag: "bp-affiliate",
          storeGetActions: [],
        },
      },
      {
        path: "affiliate-field-report/:fieldId",
        component: AffiliateFieldReport,
        name: "buyer-bp-affiliate-field-report",
        meta: {
          title: "Field Report | Parcel",
          requiredFeatureFlag: "bp-affiliate",
          storeGetActions: [],
        },
      },
      {
        path: "orders",
        component: BuyerOrders,
        name: "buyer-orders",
        meta: {
          title: "Orders | Parcel",
          storeGetActions: ["getBuyerCropOrders", "getBuyerAllocations"],
        },
      },
      {
        path: "fields/:vendorId/:fieldId",
        component: BuyerOrdersSingleOrder,
        name: "buyer-orders-single-order",
        meta: {
          title: "Order | Parcel",
        },
      },
      {
        path: "orders/:orderId",
        name: "buyer-view-order",
        component: ViewCropOrder,
        meta: {
          auth: true,
          title: "View Order | Parcel",
          displayTitle: "View Order",
        },
      },
      {
        path: "orders/delivery/:deliveryId",
        name: "buyer-view-order-delivery",
        component: ViewCropDelivery,
        meta: {
          auth: true,
          title: "View Delivery | Parcel",
          displayTitle: "View Delivery",
        },
      },
      {
        path: "inquiries",
        component: BuyerInquiries,
        name: "buyer-inquiries",
        meta: {
          title: "Inquiry | Parcel",
          storeGetActions: ["getBuyerCropOrders"],
        },
      },
      {
        path: "inquiry/:inquiryId",
        component: BuyerInquiriesSingle,
        name: "buyer-inquiry-single",
        meta: {
          title: "Inquiry | Parcel",
        },
      },
      {
        path: "originators",
        component: BuyerMarketOriginators,
        name: "buyer-originators",
        meta: {
          title: "Originators | Parcel",
          storeGetActions: ["getContracts"],
        },
      },
      {
        path: "originators/:originatorId",
        component: EditOriginator,
        name: "buyer-originator-single",
        meta: {
          title: "Originator | Parcel",
        },
      },

      {
        path: "offers",
        component: BuyerMarketOffers,
        name: "buyer-market-offers",
        meta: {
          title: "Offers | Parcel",
          storeGetActions: ["getBuyerMarketOffers"],
        },
      },
      {
        path: "offers/:offerId",
        component: ViewOffer,
        name: "buyer-market-offer-single",
        meta: {
          title: "Offer | Parcel",
        },
      },

      {
        path: "chat",
        name: "buyer-chat",
        component: BuyerChatThread,
        meta: {
          auth: true,
          title: "Discussions | Parcel",
          displayTitle: "Chat",
          storeGetActions: ["getThreads", "getMessages"],
        },
      },
      {
        path: "chat/:threadId",
        name: "buyer-chat-thread",
        component: BuyerChatThread,
        meta: {
          auth: true,
          title: "Discussions | Parcel",
          displayTitle: "Chat",
        },
      },
      {
        path: "vendors",
        component: VendorsTable,
        name: "buyer-vendors",
        props: true,
        meta: {
          title: "Vendor | Parcel",
          // storeGetActions: ["getVendors"],
        },
      },
      {
        path: "vendor/:vendorId",
        component: EditVendor,
        name: "edit-buyer-portal-vendor",
        props: true,
        meta: {
          title: "Vendor | Parcel",
        },
      },
      {
        path: "vendor/new",
        component: NewVendor,
        name: "new-buyer-portal-vendor",
        props: true,
        meta: {
          title: "Vendor | Parcel",
        },
      },
      // BP-Controller
      {
        path: "controller-inquiries",
        component: ControllerInquiries,
        name: "buyer-bp-controller-inquiries",
        meta: {
          title: "Inquiry | Parcel",
          storeGetActions: ["getControllerCropOrders"],
        },
      },
      {
        path: "controller-inquiry/:inquiryId",
        component: ControllerInquiriesSingle,
        name: "buyer-bp-controller-inquiry-single",
        meta: {
          title: "Inquiry | Parcel",
        },
      },
      {
        path: "controller-orders",
        component: ControllerOrders,
        name: "buyer-bp-controller-orders",
        meta: {
          title: "Orders | Parcel",
          storeGetActions: [
            "getControllerCropOrders",
            "getControllerAllocations",
          ],
        },
      },
      {
        path: "controller-orders/:orderId",
        name: "buyer-bp-controller-view-order",
        component: ViewControllerCropOrder,
        meta: {
          auth: true,
          title: "View Order | Parcel",
          displayTitle: "View Order",
        },
      },
      {
        path: "controller-premiums",
        component: AllControllerPremiums,
        name: "buyer-bp-controller-premiums",
        meta: {
          title: "Premiums | Parcel",
          requiredFeatureFlag: "bp-controller",
          storeGetActions: [],
        },
      },
      {
        path: "controller-reporting",
        component: ControllerReport,
        name: "buyer-bp-controller-reporting",
        meta: {
          title: "Reporting | Parcel",
          requiredFeatureFlag: "bp-controller",
          storeGetActions: [
            "getControllerAllocations",
            "getControllerDeliveries",
          ],
        },
      },
      {
        path: "contracts/:contractId/report",
        component: ContractReport,
        name: "buyer-bp-controller-contract-report",
        meta: {
          title: "Contract | Parcel",
          requiredFeatureFlag: "bp-controller",
        },
      },
      {
        path: "controller-growers",
        component: AllControllerGrowers,
        name: "buyer-bp-controller-growers",
        meta: {
          title: "Growers | Parcel",
          requiredFeatureFlag: "bp-controller",
          storeGetActions: [],
        },
      },
      {
        path: "controller-grower-report/:growerId",
        component: ControllerGrowerReport,
        name: "buyer-bp-controller-grower-report",
        meta: {
          title: "Grower Report | Parcel",
          requiredFeatureFlag: "bp-controller",
          storeGetActions: [],
        },
      },
      {
        path: "controller-field-report/:fieldId",
        component: ControllerFieldReport,
        name: "buyer-bp-controller-field-report",
        meta: {
          title: "Field Report | Parcel",
          requiredFeatureFlag: "bp-controller",
          storeGetActions: [],
        },
      },

      {
        path: "controller-orders/delivery/:deliveryId",
        name: "buyer-bp-controller-view-order-delivery",
        component: ViewControllerCropDelivery,
        meta: {
          auth: true,
          title: "View Delivery | Parcel",
          displayTitle: "View Delivery",
        },
      },
      {
        path: "contracts",
        component: ControllerContracts,
        name: "buyer-contracts",
        meta: {
          title: "Contracts | Parcel",
          requiredFeatureFlag: "bp-controller",
          storeGetActions: ["getContracts", "getContractPremiums"],
        },
      },
      {
        path: "contracts/new",
        component: NewContract,
        name: "buyer-contract-new",
        meta: {
          title: "Contract | Parcel",
          requiredFeatureFlag: "bp-controller",
        },
      },
      {
        path: "contracts/:contractId",
        component: EditContract,
        name: "buyer-contract-single",
        meta: {
          title: "Contract | Parcel",
          requiredFeatureFlag: "bp-controller",
        },
      },
      {
        path: "affiliates",
        component: BuyerMarketAffiliates,
        name: "buyer-affiliates",
        meta: {
          title: "Affiliates | Parcel",
          requiredFeatureFlag: "bp-controller",
          storeGetActions: ["getContracts"],
        },
      },
      {
        path: "affiliates/new",
        component: NewAffiliate,
        name: "buyer-affiliate-new",
        meta: {
          title: "New Affiliate | Parcel",
          requiredFeatureFlag: "bp-controller",
        },
      },
      {
        path: "assignments",
        component: ControllerAssignmentsTable,
        name: "buyer-assignments",
        meta: {
          title: "Assignments | Parcel",
          storeGetActions: ["getBuyerMarketOffers"],
        },
      },
      {
        path: "assignments/new",
        component: NewAssignment,
        name: "buyer-assignment-new",
        meta: {
          title: "New Assignment | Parcel",
        },
      },
      {
        path: "assignments/:assignmentId",
        component: EditAssignment,
        name: "buyer-assignment-single",
        meta: {
          title: "Assignment | Parcel",
        },
      },
      // Archived
      // {
      //   path: "activity",
      //   component: RecordsTable,
      //   name: "buyer-activity",
      //   meta: {
      //     title: "Activity | Parcel",
      //     storeGetActions: [
      //       "getBuyerCropOrders",
      //       "getBuyerAllocations",
      //       "getSharedRecords",
      //       "getSharedTasks",
      //     ],
      //   },
      // },
      // Archived
      // {
      //   path: "hauling",
      //   component: HaulingReservationsAll,
      //   name: "buyer-hauling",
      //   meta: {
      //     title: "Hauling | Parcel",
      //     storeGetActions: ["getBuyerCropOrders", "getBuyerAllocations"],
      //   },
      // },
    ],
  },
];
