<template>
  <span v-if="helpText" class="z-10"
    ><ToolTip :helpText="helpText" :helpTitle="helpTitle"
  /></span>
  <button
    type="button"
    class="inline-flex items-center rounded border border-transparent px-2.5 py-1.5 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
    :class="conditionalButtonStyling"
    :disabled="disabled"
    @click="clickButton"
    :data-testid="dataTestid"
  >
    {{ buttonText }}
  </button>
</template>

<script>
import ToolTip from "@/components/modals/ToolTip.vue";
export default {
  components: {
    ToolTip,
  },
  props: {
    buttonText: { type: String },
    redButton: { type: Boolean, default: false },
    marginLeft: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    dataTestid: { type: String, default: null },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
  },
  emits: ["buttonClick"],
  computed: {
    conditionalButtonStyling() {
      let styles = {
        "bg-twilight-700": !this.redButton && !this.disabled,
        "hover:bg-twilight-800": !this.redButton && !this.disabled,
        "focus:ring-twilight-500": !this.redButton && !this.disabled,
        "bg-red-600": this.redButton && !this.disabled,
        "hover:bg-red-700": this.redButton && !this.disabled,
        "focus:ring-red-500": this.redButton && !this.disabled,
        "ml-1": this.marginLeft,
        "bg-gray-200": this.disabled,
        "hover:bg-gray-200": this.disabled,
      };
      return styles;
    },
  },
  methods: {
    clickButton() {
      // console.log("emitting");
      this.$emit("buttonClick");
    },
  },
};
</script>

<style></style>
