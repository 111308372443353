<template>
  <slot-modal
    :open="modalIsActive"
    :modalTitle="'View Record'"
    @closeModal="closeRecordModal"
  >
    <template #content>
      <div
        class="mt-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <div
          class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
        >
          <div
            class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
          >
            Record Details
          </div>
          <record-form-layout
            :recordProp="viewRecord"
            :recordType="viewRecord.category"
            :displayOnly="true"
          />
        </div>
      </div>
    </template>
  </slot-modal>
  <page-card
    :headingTitle="'Order' + $route.params.orderId"
    :headingDescription="'Order # ' + $route.params.orderId"
  >
    <template #buttonArea>
      <base-button buttonText="Back to Order" @buttonClick="navigateToOrder" />
    </template>
    <template #cardDetails>
      <div
        class="text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
      >
        Premiums
      </div>
      <div class="sm:col-span-6">
        <radio-table
          :selectOptions="premiumOptions"
          :showIcons="true"
          v-model="currentPremiumIndex"
        />
      </div>
      <div
        class="text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
      >
        Fields
      </div>
      <div class="sm:col-span-6">
        <radio-table
          :selectOptions="allocationOptions"
          :showIcons="true"
          v-model="currentAllocationId"
        />
      </div>
      <div
        class="text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
      >
        Required Records
      </div>
      <div class="sm:col-span-6">
        <radio-table
          v-if="currentPremium"
          :selectOptions="currentValidationRecordOptions"
          :showIcons="true"
          v-model="currentValidationRecordIndex"
        />
      </div>
    </template>
    <template #componentSection>
      <div
        class="my-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
      >
        Existing Records
      </div>
      <div class="sm:col-span-6">
        <table-card v-if="currentAllocation">
          <template #header>
            <table-head
              :columns="[
                {
                  name: 'category',
                  label: 'Record Type',
                  filtered: false,
                  sorted: false,
                },
                {
                  name: 'result',
                  label: 'Result',
                  filtered: false,
                  sorted: false,
                },
                {
                  name: 'completed_at',
                  label: 'Datetime',
                  filtered: false,
                  sorted: false,
                },
                {
                  name: 'delete',
                  label: '',
                  filtered: false,
                  sorted: false,
                },
                {
                  name: 'view',
                  label: '',
                  filtered: false,
                  sorted: false,
                },
              ]"
              :collection="currentAllocationMatchingTypeRecords"
              :toolbar="toolbarState.OFF"
            >
            </table-head>
          </template>

          <tbody>
            <tr
              v-for="record in currentAllocationMatchingTypeRecords"
              :key="record.rowid"
            >
              <table-data td-label="Type">
                {{ record.category }}
              </table-data>
              <table-data td-label="Result">
                {{ record.result }}
              </table-data>
              <table-data td-label="Datetime">
                {{ formatDisplayDate(record.completed_at) }}
              </table-data>
              <table-data class="text-center">
                <button @click="deleteRecord(record)" :disabled="false">
                  Delete
                </button>
              </table-data>
              <table-data class="text-center">
                <button @click="setViewRecord(record)" :disabled="false">
                  View
                </button>
              </table-data>
            </tr>
          </tbody>
        </table-card>
      </div>
      <div
        class="my-4 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <div
          class="mt-2 flex justify-between text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
        >
          New Record Details
          <base-button
            buttonText="Save Record"
            @buttonClick="createRecord"
            :margin-left="true"
          />
        </div>
        <date-input
          :wideItem="true"
          inputTitle="Date"
          inputHtmlType="text"
          :required="true"
          v-model="workingRecord.completed_at"
        />
        <p class="sm: col-span-3">
          Note: Switching record types will remove any entered data.
        </p>
        <record-form-layout
          v-if="workingRecordType"
          @update:record="workingRecord = $event"
          :recordProp="workingRecord"
          :recordType="workingRecordType"
          ref="recordFormLayoutComponent"
        />
        <p v-else class="sm:col-span-6">Please select a record type</p>
      </div>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import SlotModal from "@/components/modals/SlotModal.vue";
// import SelectDropdown from "@/components/form/SelectDropdown.vue";
import RadioTable from "@/components/form/RadioTable.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
// import { harvestUnits } from "@/components/composables/convertUnits";
import RecordFormLayout from "@/layouts/grower-portal/records/RecordFormLayout.vue";
import { useToast } from "vue-toastification";
import DateInput from "@/components/form/DateInput.vue";
import {
  getShortDisplayDate,
  getLocalTimeFromUTC,
  getUTCFromLocalTime,
} from "@/components/composables/dateUtils.js";
import { toolBarState } from "@/components/table/TableHead.vue";

const RecordTypeSchemas = require("@/layouts/grower-portal/records/RecordTypes.json");

export default {
  components: {
    PageCard,
    SlotModal,
    // SelectDropdown,
    RadioTable,
    BaseButton,
    TableCard,
    TableHead,
    TableData,
    RecordFormLayout,
    DateInput,
  },
  data() {
    return {
      toolbarState: toolBarState,
      toast: useToast(),
      recordTypes: RecordTypeSchemas.map((schema) => ({
        label: schema.category,
        value: schema.category,
      })),
      // View Record
      modalIsActive: false,
      viewRecord: null,
      // Record Creation
      currentAllocationId: parseInt(this.$route.query.allocation) || null,
      currentPremiumIndex: parseInt(this.$route.query.premiumIndex) || 0,
      currentValidationRecordIndex: 0,
      workingRecord: {
        completed_at: null,
        result: null,
        payload: {},
      },
      // Statuses
      statuses: {
        Complete: "text-green-700 bg-green-50 ring-green-600/20",
        "In progress": "text-gray-600 bg-gray-50 ring-gray-500/10",
        Archived: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
      },
    };
  },
  computed: {
    dataIsLoaded() {
      if (
        this.orderId &&
        this.$store.getters.getGrowerCropOrderById(this.orderId) &&
        this.$store.getters.getGrowerAllocationsByOrderId(this.orderId) &&
        this.$store.getters.getGrowerDeliveriesByOrderId(this.orderId)
      ) {
        return true;
      } else {
        return false;
      }
    },
    // CORE DATA
    orderId() {
      return parseInt(this.$route.params.orderId);
    },
    currentOrder() {
      if (!this.dataIsLoaded) {
        return null;
      }
      // use this.orderId to fetch the order
      return this.$store.getters.getGrowerCropOrderById(this.orderId);
    },
    currentOffer() {
      if (!this.dataIsLoaded) {
        return null;
      }
      // use this.orderId to fetch the offer
      return this.$store.getters.getGrowerMarketOfferById(
        this.currentOrder.offer,
      );
    },
    allocations() {
      if (!this.dataIsLoaded) {
        return null;
      }
      let allos = this.$store.getters.getGrowerAllocationsByOrderId(
        this.orderId,
      );
      // attach the crop records to each allocation
      return allos.map((allo) => {
        return {
          ...allo,
          crop_records: this.$store.getters.getRecordsByCrop(allo.crop),
        };
      });
    },
    validatedPremiums() {
      if (!this.dataIsLoaded || !this.currentOffer?.premiums) {
        return [];
      }
      let validatedPremiums = this.currentOffer.premiums.map((premium) => {
        return {
          ...premium,
          value: this.calculateFixedPremium(premium),
          allocations: this.allocations.map((allocation) =>
            this.validateAllocationAgainstPremium(premium, allocation),
          ),
        };
      });
      // I want to iterate through the validated premiums. For each premium, I want to iterate through the allocations. For each allocation, I want to count the number of records that meet the requirements of the premium. I want to add a property to the premium called allocations_met that is the count of the number of allocations that meet the requirements of the premium.
      validatedPremiums = validatedPremiums.map((premium) => {
        return {
          ...premium,
          allocations_met: premium.allocations.filter(
            (allocation) => allocation.premium_requirements_met,
          ).length,
        };
      });
      return validatedPremiums;
    },
    //
    // OPTIONS FOR RADIO SELECTIONS
    //
    // Premiums
    //
    premiumOptions() {
      if (!this.dataIsLoaded || this.validatedPremiums.length === 0) {
        return [];
      }
      return this.validatedPremiums.map((premium, index) => ({
        primary:
          "$ " +
          premium.value +
          " - " +
          premium.allocations_met +
          " of " +
          this.allocations.length +
          " allocations met",
        completed: premium.allocations_met === this.allocations.length,
        value: index,
      }));
    },
    currentPremium() {
      if (!this.dataIsLoaded || this.validatedPremiums.length === 0) {
        return null;
      }
      return this.validatedPremiums[this.currentPremiumIndex];
    },
    //
    // Allocations
    //
    allocationOptions() {
      if (!this.dataIsLoaded || this.validatedPremiums.length === 0) {
        return [];
      }
      let allos = this.validatedPremiums[this.currentPremiumIndex]?.allocations;
      // attach the field to each allocation
      allos = allos.map((allo) => {
        return {
          ...allo,
          field_data: this.$store.getters.getFieldByCropId(allo.crop),
        };
      });
      // for allo in allos, create the label and value where label is the allo.field_data.properties.name name and value is the allocation.id
      return allos.map((allo) => ({
        primary: allo.field_data.properties.name,
        completed: allo.premium_requirements_met,
        value: allo.id,
      }));
    },
    currentAllocation() {
      if (!this.dataIsLoaded || this.validatedPremiums.length === 0) {
        return null;
      }
      let allos = this.validatedPremiums[this.currentPremiumIndex].allocations;
      return allos.find(
        (allocation) => allocation.id == this.currentAllocationId,
      );
    },
    currentAllocationMatchingTypeRecords() {
      if (!this.dataIsLoaded || !this.currentAllocation) {
        return [];
      }
      return this.currentAllocation.crop_records.filter(
        (record) => record.category == this.workingRecordType,
      );
    },
    //
    // Validation Records
    //
    currentValidationRecordOptions() {
      if (!this.dataIsLoaded || !this.currentAllocation) {
        return [];
      }
      // for record in currentAllocation.validation_records, create the label and value where label is the record.category and value is the record.category
      // the value is the index of the record in the currentAllocation.validation_records
      return this.currentAllocation.validation_records.map((record, index) => ({
        primary: record.category,
        secondary: record.payload.numberRequired,
        description: record.payload.requirements,
        value: index,
        completed: record.requirements_met,
      }));
    },
    workingRecordType() {
      if (!this.dataIsLoaded || !this.currentAllocation) {
        return null;
      }
      return this.currentAllocation.validation_records[
        this.currentValidationRecordIndex
      ].category;
    },
  },
  methods: {
    formatDisplayDate(date) {
      if (!date) {
        return "N/A";
      }
      return getShortDisplayDate(getLocalTimeFromUTC(date));
    },
    calculateFixedPremium(premium) {
      if (premium.type != "fixed") {
        return "N/A";
      }
      if (premium.total_or_unit === "total") {
        return premium.base_premium;
      }
      if (premium.total_or_unit === "unit") {
        return premium.base_premium * this.currentOrder.quantity;
      }
      return premium.base_premium;
    },
    validateAllocationAgainstPremium(premium, allocation) {
      // clone the premium.validation_records into allocation.validation_records
      // pull the records for the allocation crop
      // for each validation record in allocation.validation_records
      // if validation_record.payload.numberRequired === "One" and the allocation crop records contain a record that matches the validation record type add requirements_met: true to the allocation.validation_records record
      // if validation_record.payload.numberRequired === "Multiple" and the allocation crop records contain greater than one record that matches the validation record type add requirements_met: true to the allocation.validation_records record
      // else add requirements_met: false to the allocation.validation_records record
      // return the allocation
      if (!this.dataIsLoaded) {
        return {};
      }
      let allo = { ...allocation };
      allo.validation_records = premium.validation_records.map((record) => {
        let requirements_met = false;
        let matchingRecords = allo.crop_records?.filter(
          (cropRecord) => cropRecord.category == record.category,
        );
        if (record.payload.numberRequired === "One") {
          if (matchingRecords && matchingRecords.length > 0) {
            requirements_met = true;
          }
        }
        if (record.payload.numberRequired === "Multiple") {
          if (matchingRecords && matchingRecords.length > 1) {
            requirements_met = true;
          }
        }
        return { ...record, requirements_met };
      });
      // if all premium_requirements_met are true, add a requirements_met: true to the allocation
      allo.premium_requirements_met = allo.validation_records.every(
        (record) => record.requirements_met,
      );
      allo.number_of_requirements_met = allo.validation_records.filter(
        (record) => record.requirements_met,
      ).length;
      allo.crop_data = this.$store.getters.getAnyCropById(allo.crop);
      allo.field_data = this.$store.getters.getFieldByCropId(allo.crop);
      return allo;
    },
    setPremiumIndex(premiumIndex) {
      this.currentPremiumIndex = premiumIndex;
    },
    setViewRecord(record) {
      this.viewRecord = record;
      this.modalIsActive = true;
    },
    deleteRecord(record) {
      this.$store.dispatch("deleteRecord", record);
      //   this.toast.success("Record deleted");
    },
    closeRecordModal() {
      this.modalIsActive = false;
    },
    navigateToOrder() {
      this.$router.push({
        name: "grower-portal-offer-order",
        params: { orderId: this.orderId },
      });
    },
    async createRecord() {
      if (!this.workingRecord.completed_at) {
        this.toast.error("Please enter a date for your record");
        return;
      }
      let submissionRecord = {
        category: this.workingRecordType,
        payload: this.workingRecord.payload,
        result: this.workingRecord.result,
        crop: this.currentAllocation.crop,
        completed_at: getUTCFromLocalTime(this.workingRecord.completed_at),
      };
      console.log("submissionRecord", submissionRecord);
      // make sure that the payload isn't {} and the result isn't null. One is ok, but not both.
      // assert that the payload isn't {} AND the result isn't null
      if (
        JSON.stringify(submissionRecord.payload) === "{}" &&
        submissionRecord.result === null
      ) {
        this.toast.error("Please enter record details");
        console.error("Please fill out the record form");
        return;
      }
      await this.$store.dispatch("createRecord", submissionRecord, false);
      this.toast.success("Record created");
      this.workingRecord = {
        completed_at: null,
        result: null,
        payload: {},
      };
      // call     resetRecord(newRecord)
      // in the child recordFormLayout component
      this.$refs.recordFormLayoutComponent.resetRecord(this.workingRecord);
    },
  },
  watch: {
    // watch the currentAllocationId and update the allocation query parameter
    currentPremiumIndex(newVal) {
      this.$router.push({
        query: {
          premiumIndex: newVal,
          allocation: this.currentAllocationId,
        },
      });
    },
    currentAllocationId(newVal) {
      this.$router.push({
        query: {
          allocation: newVal,
          premiumIndex: this.currentPremiumIndex,
        },
      });
    },
  },
};
</script>
