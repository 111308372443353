import client from "@/api/parcel/api.js";
import {
  calculateFixedPremium,
  validateAllocationAgainstPremium,
  aggregateValidatedPremiumData,
} from "@/store/parcelApi/grower/storeGrowerFuntions.js";
import _ from "lodash";

export default {
  state() {
    return {
      growerCropOrders: [],
    };
  },
  mutations: {
    SET_GROWER_CROP_ORDERS(state, orderData) {
      state.growerCropOrders = orderData;
    },
    ADD_GROWER_CROP_ORDER(state, orderObject) {
      state.growerCropOrders.push(orderObject);
    },
    UPDATE_GROWER_CROP_ORDER(state, orderObject) {
      state.growerCropOrders = state.growerCropOrders.filter(function (obj) {
        return obj.id !== orderObject.id;
      });
      state.growerCropOrders.push(orderObject);
    },
    DELETE_GROWER_CROP_ORDER(state, orderObject) {
      state.growerCropOrders = state.growerCropOrders.filter(function (obj) {
        return obj.id !== orderObject.id;
      });
    },
    RESET_GROWER_CROP_ORDERS(state) {
      state.growerCropOrders = [];
    },
  },
  actions: {
    async getGrowerCropOrders({ commit }) {
      const data = await client.grower.cropOrders.read();
      if (data) {
        commit("SET_GROWER_CROP_ORDERS", data.orders);
      }
    },
    async createGrowerCropOrder({ commit }, order) {
      const data = await client.grower.cropOrders.create(null, order);
      if (data) {
        // console.log(data);
        commit("ADD_GROWER_CROP_ORDER", data);
        this.dispatch("getGrowerCropOrders");
        return data;
      }
    },
    async updateGrowerCropOrder({ commit }, order) {
      const data = await client.grower.cropOrders.update(order.id, order);
      if (data) {
        commit("UPDATE_GROWER_CROP_ORDER", data);
        this.dispatch("getGrowerCropOrders");
      }
    },
    async deleteGrowerCropOrder({ commit }, order) {
      // first remove any child allocations from this order
      const allocations = this.getters.getGrowerAllocationsByOrderId(order.id);
      if (allocations.length > 0) {
        for (let allocation of allocations) {
          this.dispatch("deleteAllocation", allocation);
        }
      }
      const data = await client.grower.cropOrders.delete(order.id);
      if (data) {
        // console.log(data);
        commit("DELETE_GROWER_CROP_ORDER", order);
        this.dispatch("getGrowerCropOrders");
      }
    },
    async uploadGrowerCropOrderAttachment(
      { commit, getters },
      orderIdAndAttachment,
    ) {
      // console.log("uploadGrowerOrderAttachment", orderIdAndAttachment.file);
      const data = await client.grower.cropOrders.createAttachment(
        orderIdAndAttachment.parentId,
        orderIdAndAttachment.file,
      );
      if (data) {
        let order = getters.getGrowerCropOrderById(
          orderIdAndAttachment.parentId,
        );
        order.attachments.push(data);
        commit("UPDATE_GROWER_CROP_ORDER", order);

        return data;
        // await this.dispatch("getVendorProducts");
      }
    },
    async removeGrowerCropOrderAttachment({ commit, state }, attachmentId) {
      let currentOrder = null;
      for (let order of state.growerCropOrders) {
        if (!order?.attachments) {
          continue;
        }
        for (let attachment of order.attachments) {
          if (attachment.attachment_id === attachmentId) {
            currentOrder = order;
            break;
          }
        }
        if (currentOrder) {
          break;
        }
      }
      const data =
        await client.grower.cropOrders.removeAttachment(attachmentId);
      if (data) {
        if (currentOrder) {
          currentOrder.attachments = currentOrder.attachments.filter(
            (a) => a.attachment_id !== attachmentId,
          );
          commit("UPDATE_GROWER_CROP_ORDER", currentOrder);
        }
        return data;
        // await this.dispatch("getVendorProducts");
      }
    },
  },
  getters: {
    getGrowerCropOrdersByListingId: (state) => (listingId) => {
      return state.growerCropOrders.filter(
        (order) => order.listing === parseInt(listingId),
      );
    },
    getGrowerCropOrderById: (state) => (orderId) => {
      return state.growerCropOrders.find(
        (order) => order.id === parseInt(orderId),
      );
    },
    // getGrowerCropOrderBuyers: (state) => {
    //   return state.growerCropOrders.reduce((buyers, order) => {
    //     if (buyers[order.buyer_details.id] == null) {
    //       buyers[order.buyer_details.id] = order.buyer;
    //     }
    //     return buyers;
    //   }, {});
    // },
    getGrowerCropOrders:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedGrowerOrders = state.growerCropOrders.map((order) => ({
          ...order,
          // seller_name: order.seller_details.name,
        }));
        // _.isEmpty(filter) will return true for functions. Added filterFunction to check for and execute functions
        let functionFilterGrowerOrders = filterFunction
          ? _.filter(expandedGrowerOrders, filterFunction)
          : expandedGrowerOrders;
        let filteredGrowerOrders = _.isEmpty(filter)
          ? functionFilterGrowerOrders
          : _.filter(functionFilterGrowerOrders, filter);
        let matchedGrowerOrders = _.isEmpty(match)
          ? filteredGrowerOrders
          : _.filter(filteredGrowerOrders, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortedGrowerOrders = _.isEmpty(sort)
          ? matchedGrowerOrders
          : _.sortBy(matchedGrowerOrders, sort);
        let paginatedGrowerOrders =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedGrowerOrders,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedGrowerOrders;
        return paginatedGrowerOrders;
      },

    getDerivedGrowerCropOrders:
      (state, getters) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        //let expandedGrowerCropOrders = state.growerCropOrders;

        let expandedGrowerCropOrders = state.growerCropOrders.map((order) => {
          let buyer = {};
          if (order.buyer) {
            buyer = order.buyer;
          } else {
            buyer = getters.getCustomerById(order.customer);
          }
          let allocationAcres = getters.getSumAllocationAcresByOrderId(
            order.id,
          );
          let listing = getters.getGrowerListingById(order.listing);
          //console.log("derived data", order, buyer, allocationAcres);

          return {
            ...order,
            buyer,
            allocationAcres,
            listing,
          };
        });

        // This section checks if a filter or set of filters exists. If it does, it loops through and checks if any have nested objects to filter,
        // like "user.name". If it does, it substitutes the "user.name:value" key for an actual object {user{name:value}}
        // NOTE: only currently used in tasks, if it becomes generally needed, we can move to TableHeaderCellWithFilterButton to make it take effect
        // globally.
        if (
          filter &&
          Object.keys(filter).length > 0 &&
          Object.getPrototypeOf(filter) === Object.prototype
        ) {
          //console.log("break up", filter);
          let newFilter = {};
          // loop through each key in the filter to see if we need to parse it.
          for (let key in filter) {
            //console.log("filterClause", `${key}:${filter[key]}`);
            if (key && key.split(".").length > 1) {
              var schema = newFilter; // a moving reference to internal objects within obj
              var pList = key.split(".");
              var len = pList.length;

              for (var i = 0; i < len - 1; i++) {
                var elem = pList[i];
                if (!schema[elem]) schema[elem] = {};
                schema = schema[elem];
              }
              schema[pList[len - 1]] = filter[key];
              //console.log("cleaned",newFilter);
            } else {
              // Not one we need to break up, just copy it to our new object.
              newFilter[key] = filter[key];
            }
          }
          filter = newFilter;
        }
        // _.isEmpty(filter) will return true for functions. Added filterFunction to check for and execute functions
        let functionFilterGrowerOrders = filterFunction
          ? _.filter(expandedGrowerCropOrders, filterFunction)
          : expandedGrowerCropOrders;
        let filteredGrowerCropOrders = _.isEmpty(filter)
          ? functionFilterGrowerOrders
          : _.filter(functionFilterGrowerOrders, filter);
        let matchedGrowerCropOrders = _.isEmpty(match)
          ? filteredGrowerCropOrders
          : _.filter(filteredGrowerCropOrders, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedGrowerCropOrders = _.isEmpty(sort)
          ? matchedGrowerCropOrders
          : _.orderBy(matchedGrowerCropOrders, sortColumns, sortOrders);
        let paginatedGrowerCropOrders =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedGrowerCropOrders,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedGrowerCropOrders;
        return paginatedGrowerCropOrders;
      },
    // MAKE SURE ALL DATA IS LOADED, EITHER FROM WITHIN THIS GETTER OR BEFORE CALLING THIS GETTER
    getGrowerCropOrderPremiumAggregate:
      (state, getters, rootState, rootGetters) => (orderId) => {
        let order = state.growerCropOrders.find(
          (order) => order.id === orderId,
        );
        let offer = rootGetters.getGrowerMarketOfferById(order.offer);
        let allocations = rootGetters.getGrowerAllocationsByOrderId(orderId);
        // let records = rootGetters.getRecords;
        // for each of the above,
        if (!order || !offer || !allocations) {
          // console.log("order", order);
          // console.log("offer", offer);
          // console.log("allocations", allocations);
          // console.log("records", records);
          return {
            order: null,
            offer: null,
            allocations: null,
            validated_premiums: null,
            validated_premium_aggregates: null,
          };
        }
        // attach the crop records to each allocation
        allocations.map((allo) => {
          return {
            ...allo,
            crop_records: rootGetters.getRecordsByCrop(allo.crop),
          };
        });
        let validatedPremiums = offer.premiums.map((premium) => {
          return {
            ...premium,
            value: calculateFixedPremium(premium, order.quantity),
            // allocations should have the validateAllocationAgainst premium function applied to them and getters to add crop and field data
            // allo.crop_data = this.$store.getters.getAnyCropById(allo.crop);
            // allo.field_data = this.$store.getters.getFieldByCropId(allo.crop);
            allocations: allocations.map((allocation) =>
              validateAllocationAgainstPremium(premium, allocation),
            ),
          };
        });
        validatedPremiums = validatedPremiums.map((premium) => {
          return {
            ...premium,
            // Might not need the crop_data and field_data in the validated premiums list
            // allocations: premium.allocations.map((allocation) => {
            //   return {
            //     ...allocation,
            //     crop_data: rootGetters.getAnyCropById(allocation.crop),
            //     field_data: rootGetters.getFieldByCropId(allocation.crop),
            //   };
            // }),
            allocations_met: premium.allocations.filter(
              (allocation) => allocation.premium_requirements_met,
            ).length,
          };
        });
        let validatedPremiumAggregates =
          aggregateValidatedPremiumData(validatedPremiums);
        // add the validatedPremiumAggregates object to the premiumAggregate object
        let premiumAggregate = {
          order: order,
          offer: offer,
          allocations: allocations,
          validated_premiums: validatedPremiums,
          validated_premium_aggregates: validatedPremiumAggregates,
        };
        return premiumAggregate;
      },
    // Inquiries
    getPartnerInquiries: (state) => {
      return state.growerCropOrders.filter(
        (order) => order.status === "Inquiry",
      );
    },
    getPartnerInquiriesByBuyer: (state, getters) => (buyer) => {
      const orders = state.growerCropOrders.filter(
        (order) => order.buyer_details.id == buyer,
      );
      const inquiries = orders.filter((order) => order.status === "Inquiry");
      return inquiries.map((order) => {
        order.allocated_acres = getters
          .getGrowerAllocationsByOrder(order.id)
          .reduce((acres, alloc) => {
            return acres + (alloc.acres || 0);
          }, 0);
        return order;
      });
    },
    getPartnerInquiryBuyers: (state) => {
      const orders = state.growerCropOrders.filter(
        (order) => order.status === "Inquiry",
      );
      // console.log("Inquiry", orders);
      return orders.reduce((buyers, order) => {
        if (buyers[order.buyer_details.id] == null) {
          buyers[order.buyer_details.id] = order.buyer;
          buyers[order.buyer_details.id].tons_inquired = 0;
          // buyers[order.buyer_details.id].chat_thread_id = 0;
        }
        buyers[order.buyer_details.id].tons_inquired += order.quantity;
        return buyers;
      }, {});
    },
  },
};
