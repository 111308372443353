<template>
  <div class="bg-white px-4 py-5 sm:rounded-t-lg sm:px-6">
    <div
      class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap"
    >
      <div class="ml-4 mt-4">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          Allocations for Field:
          {{
            field
              ? field.properties.name +
                " (" +
                field.properties.acres +
                " acres)"
              : ""
          }}
        </h3>
      </div>
      <div class="ml-4 mt-4 flex-shrink-0">
        <base-button
          buttonText="Save"
          :marginLeft="false"
          @buttonClick="saveAcres"
        />
      </div>
    </div>
  </div>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'buyer.name', label: 'Buyer' },
          { name: 'quantity', label: 'Quantity Ordered', filtered: false },
          { name: 'units', label: 'Order Units', filtered: false },
          {
            name: 'allocationAcres',
            label: 'Total Allocated Acres',
            filtered: false,
          },
          {
            name: 'estimatedAllocatedQty',
            label: 'Est. Allocated Qty',
            filtered: false,
          },
          { name: 'actions', label: '', filtered: false, sorted: false },
          {
            name: 'actions',
            label: 'Current Allocated Acres',
            filtered: false,
            sorted: false,
          },
        ]"
        :collection="orders"
        @update:derived-collection="derivedOrders = $event"
      >
      </table-head>
    </template>
    <tbody>
      <tr v-for="order in derivedOrders" :key="order.id">
        <table-data td-label="Buyer">
          {{ order.buyer_details?.name }}
        </table-data>
        <table-data td-label="Quantity Ordered">
          {{ order.quantity }}
        </table-data>
        <table-data td-label="Order Units">
          {{ order.units }}
        </table-data>
        <table-data td-label="Total Allocated Acres">
          {{ order.allocationAcres }}
        </table-data>
        <table-data td-label="Est. Allocated Qty">
          {{ order.estimatedAllocatedQty }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="orderPageNavigation(order)"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit Order
          </router-link>
        </table-data>
        <table-data class="text-center">
          <table-body-cell-edit-allocation-acres
            :allocationId="order.id"
            :acres="order.allocationAcres"
            @updateAcres="updateAcres"
          />
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>
<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";

import { tonsToBushels } from "@/components/composables/convertUnits";
import TableBodyCellEditAllocationAcres from "@/layouts/grower-portal/sell/orders/TableBodyCellEditAllocationAcres.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    TableBodyCellEditAllocationAcres,
    BaseButton,
  },
  data() {
    return {
      orderAcres: [], // {orderId: 1, acres: 10}
      derivedOrders: [],
      searchValue: "",
      pageSorts: [],
    };
  },
  computed: {
    field() {
      return this.$store.getters.getFieldById(this.$route.params.fieldId);
    },
    orders() {
      let derived = this.$store.getters.getDerivedGrowerCropOrders({
        filter: { status: "In Progress" }, // {crop: "Strawberry"} - return elements where crop is exactly "Strawberry"
        match: this.searchValue, // "Pajaro" - return all elements containing "pajaro"/"PAJARO"/"pAjArO"
        sort: this.pageSorts,
      });

      derived = derived.map((order) => {
        return {
          ...order,
          allocationAcres: this.$store.getters
            .getGrowerAllocationsByOrderId(order.id)
            ?.filter(
              (allocation) =>
                allocation.crop === this.field?.properties.current_crop.id,
            )
            ?.reduce((total, allocation) => {
              return total + allocation.acres;
            }, 0),
          estimatedAllocatedQty: this.estimatedAllocatedQty(order.id),
        };
      });
      // sort by allocationAcres descending
      derived.sort((a, b) => {
        return b.allocationAcres - a.allocationAcres;
      });
      return derived;
    },
  },
  methods: {
    updateAcres(event) {
      // console.log("updateAcres", event);
      if (
        this.orderAcres.find((orderAcres) => orderAcres.orderId === event.id)
      ) {
        this.orderAcres[
          this.orderAcres.findIndex(
            (orderAcres) => orderAcres.orderId === event.id,
          )
        ].acres = event.acres;
      } else this.orderAcres.push({ orderId: event.id, acres: event.acres });
    },
    saveAcres() {
      // console.log("updateAcres");
      // loop through the allocations and update the acres
      // three cases - 1) new allocation, 2) adjustment to existing allocation 3) removal of existing allocation if acres = 0
      this.derivedOrders.forEach((order) => {
        let allocation = this.$store.getters
          .getGrowerAllocationsByOrderId(order.id)
          ?.find(
            (allocation) =>
              allocation.crop === this.field?.properties.current_crop.id,
          );
        let newAcres = this.orderAcres.find(
          (orderAcres) => orderAcres.orderId === order.id,
        )?.acres;

        if (newAcres !== undefined) {
          // console.log("save order", order.id, allocation, newAcres);
          if (allocation) {
            if (newAcres === 0) {
              // remove the allocation
              // console.log("remove the allocation", allocation);
              this.$store.dispatch("deleteGrowerAllocation", allocation);
            } else {
              // update the allocation
              // console.log("update the allocation...", allocation.id, newAcres);
              this.$store.dispatch("updateGrowerAllocation", {
                order: allocation.order,
                id: allocation.id,
                crop: allocation.crop,
                acres: newAcres,
              });
            }
          } else {
            if (newAcres > 0) {
              // create a new allocation
              this.$store.dispatch("createGrowerAllocation", {
                crop_order: order.id,
                crop: this.field.properties.current_crop.id,
                acres: newAcres,
              });
            }
          }
        }
      });
      this.$router.push({
        name: "grower-portal-produce-allocations",
        query: { tab: 1 },
      });
    },
    sumAllocationAcresByOrderId(orderId) {
      return this.$store.getters.getSumAllocationAcresByOrderId(orderId);
    },
    estimatedAllocatedQty(orderId) {
      let tpaEstimates = [];
      let cropType = "";
      let units = this.$store.getters.getGrowerCropOrderById(orderId).units;
      for (let allocation of this.$store.getters.getGrowerAllocationsByOrderId(
        orderId,
      )) {
        let crop = this.$store.getters.getAnyCropById(allocation.crop);
        if (!crop || !crop.active) {
          continue;
        }
        if (crop.field !== this.field.id) {
          continue;
        }
        if (crop.details?.tpa) {
          tpaEstimates.push(parseFloat(crop?.details?.tpa));
        }
        // console.log("crop", cropType, units);

        if (!cropType.includes(crop?.crop_type)) {
          cropType += crop?.crop_type;
        }
      }
      if (tpaEstimates.length > 0) {
        let tons =
          this.median(tpaEstimates) * this.sumAllocationAcresByOrderId(orderId);
        let qty = tons; // default is estimate in tons
        if (units == "Bushels") {
          qty = tonsToBushels(cropType, tons);
        }
        return qty.toFixed(1);
      } else {
        return "Set at least one allocated field's TPA to show estimate";
      }
    },
    median(values) {
      if (values.length === 0) throw new Error("No inputs");
      values.sort(function (a, b) {
        return a - b;
      });
      var half = Math.floor(values.length / 2);
      if (values.length % 2) return values[half];
      return (values[half - 1] + values[half]) / 2.0;
    },
    orderPageNavigation(order) {
      if (order.offer) {
        return {
          name: "grower-portal-offer-order",
          params: { orderId: order.id },
        };
      } else {
        return {
          name: "grower-portal-produce-order",
          params: { orderId: order.id },
        };
      }
    },
  },
};
</script>
