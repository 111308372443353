<template>
  <div class="mt-6 overflow-hidden bg-white p-4 shadow sm:rounded-md">
    <div class="flex">
      <base-button
        @click="navigateSettings"
        buttonText="Step 1: Setup integration with Trimble"
        :disabled="setup"
      />
      <template v-if="setup"
        ><check-circle-icon class="h-8 w-8 fill-green-500"
      /></template>
    </div>
    <div class="flex">
      <base-button
        @click="importTrimble"
        buttonText="Step 2: Transfer fields"
        :disabled="importing || !setup"
      />
      <template v-if="importReport !== null"
        ><check-circle-icon class="h-8 w-8 fill-green-500"
      /></template>
    </div>
  </div>
  <modal :show="showReport" title="Import Report">
    <div v-if="importReport !== null">
      <div>
        {{ importReport }}
      </div>
      <base-button @click="closeReport" buttonText="Close" />
    </div>
    <div v-else>Loading data...</div>
  </modal>
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/solid";
import BaseButton from "@/components/buttons/BaseButton.vue";
import Modal from "@/components/modals/PopupModal.vue";
import client from "@/api/parcel/api.js";

export default {
  components: {
    BaseButton,
    Modal,
    CheckCircleIcon,
  },
  data() {
    const setup =
      this.$store.getters.getIntegrationByType("trimble") !== undefined;
    return {
      setup,
      importing: false,
      importReport: null,
      showReport: false,
    };
  },
  computed: {},
  methods: {
    closeReport() {
      this.showReport = false;
      this.importing = false;
    },
    async importTrimble() {
      this.importing = true;
      const data = await client.fields.transfer();
      if (data) {
        this.showReport = true;
        if (data.exception) {
          const data = await client.integrations.trimbleSetup();
          window.location = data.link;
        }
        if (data.trimble_org) {
          this.importReport = `Import started from ${data.trimble_org}. A notification will be sent when transfer is complete.`;
        }
      }
    },
    navigateSettings() {
      this.$router.push({
        name: "grower-portal-settings-tab",
        params: { tab: "integrations" },
      });
    },
  },
};
</script>
