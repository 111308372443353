<template>
  <!-- listing dashboard - 
    Can go right into an AppFrame router-view OR  
  is a Left Column Component within an app-layout/TwoColumn.vue component
     -->

  <div class="grid grid-cols-1 gap-4 lg:col-span-2">
    <!-- Welcome panel -->
    <section aria-labelledby="profile-overview-title">
      <div class="overflow-hidden rounded-lg bg-white shadow">
        <div class="bg-white p-6">
          <div class="sm:flex sm:items-center sm:justify-between">
            <div class="sm:flex sm:space-x-5">
              <div class="flex-shrink-0">
                <CurrencyDollarIcon
                  class="mx-auto h-20 w-20 flex-shrink-0 rounded-full text-emerald-600 group-hover:text-indigo-500"
                  aria-hidden="true"
                />
                <!--<img class="w-20 h-20 mx-auto rounded-full" :src="user.imageUrl" alt="" /> -->
              </div>
              <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                <p class="py-5 text-3xl font-medium text-gray-600">
                  Crop Sales
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="grid grid-cols-1 border-t border-gray-200 divide-y divide-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-y-0 sm:divide-x"
        >
          <div
            v-for="stat in stats"
            :key="stat.label"
            class="px-6 py-5 text-lg font-medium text-center"
          >
            <span class="text-gray-900">{{ stat.value }}</span>
            {{ " " }}
            <span class="text-gray-600">{{ stat.label }}</span>
          </div>
        </div> -->
      </div>
    </section>

    <!-- Actions panel -->
    <section aria-labelledby="quick-links-title">
      <div
        class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0"
      >
        <h2 class="sr-only" id="quick-links-title">Quick links</h2>
        <div
          v-for="(action, actionIdx) in actions"
          :key="action.name"
          :class="[
            actionIdx === 0
              ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
              : '',
            actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
            actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
            actionIdx === actions.length - 1
              ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
              : '',
            'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500',
          ]"
        >
          <div>
            <span
              :class="[
                action.iconBackground,
                action.iconForeground,
                'inline-flex rounded-lg p-3 ring-4 ring-white',
              ]"
            >
              <component :is="action.icon" class="h-6 w-6" aria-hidden="true" />
            </span>
          </div>
          <div class="mt-8">
            <h3 class="text-lg font-medium">
              <router-link
                :to="{ name: action.routeName }"
                class="focus:outline-none"
              >
                <!-- Extend touch target to entire panel -->
                <span class="absolute inset-0" aria-hidden="true" />
                {{ action.name }}
              </router-link>
            </h3>
            <p class="mt-2 text-sm text-gray-500">
              {{ action.blurb }}
            </p>
          </div>
          <span
            class="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
            aria-hidden="true"
            >Click here to select
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"
              />
            </svg>
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  // AcademicCapIcon,
  BellIcon,
  CashIcon,
  // ClockIcon,
  MenuIcon,
  // ReceiptRefundIcon,
  ClipboardListIcon,
  //FolderIcon,
  BookOpenIcon,
  CurrencyDollarIcon,
  MapIcon,
  XIcon,
  // UserIcon
  UsersIcon,
} from "@heroicons/vue/outline";
import { SearchIcon } from "@heroicons/vue/solid";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverButton,
    PopoverOverlay,
    PopoverPanel,
    TransitionChild,
    TransitionRoot,
    ClipboardListIcon,
    MapIcon,
    BellIcon,
    MenuIcon,
    SearchIcon,
    CurrencyDollarIcon,
    XIcon,
    UsersIcon,
  },
  computed: {
    // stats() {
    //   let produceStats = [];
    //   let fields = this.$store.state.fields.fields;
    //   let orders = this.$store.getters.getDerivedGrowerCropOrders({
    //     filter: null,
    //     match: null,
    //     sort: null,
    //   });
    //   let listings = this.$store.state.growerListings.growerListings;
    //   let fieldsWithListings = new Set(
    //     listings.map((listing) => {
    //       return listing.field;
    //     })
    //   );
    //   let fieldsWithOrders = new Set(
    //     orders.map((order) => {
    //       return order.listing?.field;
    //     })
    //   );
    //   // console.log(
    //   //   "stats",
    //   //   fields,
    //   //   listings,
    //   //   orders,
    //   //   fieldsWithListings,
    //   //   fieldsWithListings.size,
    //   //   fieldsWithOrders
    //   // );
    //   let listingPct = 0;
    //   let orderPct = 0;
    //   if (fields.length > 0  && fieldsWithListings.size >0 ) {
    //      listingPct = Math.round(fieldsWithListings.size / fields.length * 100);
    //      orderPct = Math.round(fieldsWithOrders.size / fields.length * 100);
    //   }
    //   produceStats.push({ label: "% of fields are listed", value: listingPct });
    //   produceStats.push({ label: "% of crops have been sold ", value: orderPct, });
    //   let orderAmt = 0;
    //   for (let order of orders) {
    //     //console.log("order", order)
    //      orderAmt += order.quantity * order.estimated_price;
    //   }
    //   var formatter = new Intl.NumberFormat("en-US", {
    //     style: "currency",
    //     currency: "USD",
    //     maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    //   });
    //   let orderAmtDollars = formatter.format(orderAmt);
    //   //console.log("stats", fields, fieldsWithListings, orderAmt, orderAmtDollars);
    //   produceStats.push({ label: "expected revenue", value: orderAmtDollars });
    //   //      for (let field of fields) {
    //   return produceStats;
    // },
  },
  data() {
    let inquiries = this.$store.getters.getPartnerInquiries.length;
    if (!inquiries) inquiries = 0;
    // console.log("inquiries", inquiries);

    const actions = [
      {
        icon: BookOpenIcon,
        name: "Manage Orders",
        blurb: "Add, view, and edit your crop orders. ",
        routeName: "grower-portal-produce-orders",
        iconForeground: "text-sky-700",
        iconBackground: "bg-sky-50",
      },
      {
        icon: BookOpenIcon,
        name: "Manage Inquiries (" + inquiries + ")",
        blurb: "View and manage inquiries about purchasing your crops. ",
        routeName: "grower-portal-produce-inquiries",
        iconForeground: "text-sky-700",
        iconBackground: "bg-sky-50",
      },
      {
        icon: BookOpenIcon,
        name: "Offers",
        blurb:
          "View available offers to purchase crops or incentives to follow a specific practice. ",
        routeName: "grower-portal-market-offers",
        iconForeground: "text-sky-700",
        iconBackground: "bg-sky-50",
      },
      {
        icon: CashIcon,
        name: "Your Listings",
        blurb: "Add, view, and edit your crop listings",
        routeName: "grower-portal-produce-listings",
        iconForeground: "text-teal-700",
        iconBackground: "bg-teal-50",
      },
      {
        icon: ClipboardListIcon,
        name: "Allocations Report",
        blurb: "View available / sold status for all fields",
        routeName: "grower-portal-produce-allocations",
        iconForeground: "text-teal-700",
        iconBackground: "bg-teal-50",
      },
      {
        icon: UsersIcon,
        name: "Manage Customers",
        blurb: "Mange customers who have ordered crops from you",
        routeName: "grower-portal-customers",
        iconForeground: "text-sky-700",
        iconBackground: "bg-sky-50",
      },
    ];
    return {
      actions,
    };
  },
};
</script>
