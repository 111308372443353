<template>
  <div class="lg:col-start-3 lg:row-end-1">
    <h2 class="sr-only">{{ title }}</h2>
    <div
      class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5"
      :class="buttonText ? '' : 'pb-6'"
    >
      <dl class="flex flex-wrap">
        <div class="flex-auto pl-6 pt-6">
          <dt class="text-sm font-semibold leading-6 text-gray-900">
            {{ title }}
          </dt>
          <dd class="mt-1 text-base font-semibold leading-6 text-gray-900">
            {{ titleStat }}
          </dd>
        </div>
        <div class="flex-none self-end px-6 pt-4" v-if="statusText">
          <dt class="sr-only">Status</dt>
          <dd
            v-if="!statusRed"
            class="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20"
          >
            {{ statusText }}
          </dd>
          <dd
            v-else
            class="rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-600/20"
          >
            {{ statusText }}
          </dd>
        </div>
        <div
          class="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6"
        >
          <dt class="flex-none">
            <span class="sr-only">{{ primaryStat.text }}</span>
            <OutlineHeroIcon
              :name="primaryStat.icon"
              classProp="h-6 w-5 text-gray-400"
              aria-hidden="true"
              v-if="primaryStat.icon"
            />
          </dt>
          <dd class="text-sm font-medium leading-6 text-gray-900">
            {{ primaryStat.text }}
          </dd>
        </div>
        <div
          v-for="stat in secondaryStats"
          :key="stat.text"
          class="mt-4 flex w-full flex-none gap-x-4 px-6"
        >
          <dt class="flex-none">
            <span class="sr-only">{{ stat.text }}</span>
            <OutlineHeroIcon
              :name="stat.icon"
              classProp="h-6 w-5 text-gray-400"
              aria-hidden="true"
              v-if="stat.icon"
            />
          </dt>
          <dd class="text-sm leading-6 text-gray-500">
            <time datetime="2023-01-31">{{ stat.text }}</time>
          </dd>
        </div>
      </dl>
      <div v-if="buttonText" class="mt-6 border-t border-gray-900/5 px-6 py-6">
        <button
          @click="handleClick"
          class="text-sm font-semibold leading-6 text-gray-900"
        >
          {{ buttonText }} <span aria-hidden="true">&rarr;</span>
        </button>
      </div>
      <!-- <div v-else class="mt-6 border-t border-gray-900/5 px-6 py-6">
        <p class="text-sm font-semibold leading-6 text-gray-900">
          N/A <span aria-hidden="true"></span>
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
import OutlineHeroIcon from "@/components/icons/OutlineHeroIcon.vue";

export default {
  components: {
    OutlineHeroIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    titleStat: {
      required: true,
    },
    statusText: {
      type: String,
    },
    statusRed: {
      type: Boolean,
      default: false,
    },
    primaryStat: {
      type: Object,
      required: true,
    },
    secondaryStats: {
      type: Array,
      default: () => [],
    },
    buttonText: {
      type: String,
    },
  },
  emits: ["detailCardClick"],
  methods: {
    handleClick() {
      this.$emit("detailCardClick");
    },
  },
};
</script>
