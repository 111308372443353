<template>
  <div class="mt-6 overflow-hidden bg-white p-4 shadow sm:rounded-md">
    <form @submit.prevent="saveCsv" id="import">
      <div class="flex">
        <file-select
          buttonText="Step 1: Please select a file to import (.csv)"
          @input="setFile"
          accept=".csv"
        />
        <template v-if="csv != null"
          ><check-circle-icon class="h-8 w-8 fill-green-500"
        /></template>
      </div>
      <div class="flex">
        <base-button
          @click="uploadCsvfile"
          buttonText="Step 2: Upload the file"
          :disabled="csv == null"
          loading="loading"
        />
        <template v-if="uploaded"
          ><check-circle-icon class="h-8 w-8 fill-green-500"
        /></template>
      </div>
      <div v-if="uploaded">
        <p class="mb-4 mt-4 block text-sm font-bold text-twilight-700">
          Step 3: Map the attributes
        </p>
        <map-attributes
          :attributes="productAttributes"
          :options="options"
          v-model="mappings"
        />
        <div class="mt-4">
          <submit-button
            formId="import"
            buttonText="Step 4: Import the products"
            :disabled="!csv"
          />
        </div>
      </div>
    </form>
  </div>
  <modal :show="showReport" title="Import Report">
    <div v-if="importReport">
      <div v-html="importReport" class="prose"></div>
      <base-button
        @click="showReport = false"
        buttonText="Close"
        :disabled="!uploaded"
      />
    </div>
    <div v-else>Loading data...</div>
  </modal>
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/solid";
import FileSelect from "@/components/form/FileSelect.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import MapAttributes from "@/components/import/MapAttributes.vue";
import Modal from "@/components/modals/PopupModal.vue";
import Papa from "papaparse";
import _ from "lodash";
import { dataUrlToFile } from "@/components/composables/scriptUtils.js";

export default {
  components: {
    CheckCircleIcon,
    FileSelect,
    BaseButton,
    SubmitButton,
    MapAttributes,
    Modal,
  },
  props: {
    productAttributes: { type: Object },
  },
  data() {
    return {
      csv: null,
      uploaded: false,
      listings: [],
      options: [],
      mappings: null,
      addRecords: true,
      updateRecords: true,
      importReport: "",
      showReport: false,
    };
  },
  computed: {},
  methods: {
    setFile(file) {
      this.csv = file;
    },
    async uploadCsvfile() {
      let componentHandle = this;
      let listings = [];
      let header = [];
      // console.log("uploadCsvfile");

      await Papa.parse(this.csv, {
        complete: function (results) {
          // console.log("parse done", results);
          // console.log("csvArray", results);
          // grab the first column to get column names
          for (let column of results.data[0]) {
            header.push(column);
          }
          // console.log("header", header);
          for (const [i, row] of results.data.entries()) {
            if (i > 0) {
              //skip the header
              let listing = {};
              for (let i = 0; i < header.length; i++) {
                listing[header[i]] = row[i];
              }
              listings.push(listing);
            }
          }
          // console.log("listings uploaded", listings);
          componentHandle.listings = listings;
          componentHandle.setMappingOptions();
          componentHandle.uploaded = true;
        },
      });
    },
    setMappingOptions() {
      // grab first listing in listings and use it to get the attributes
      let options = [];
      if (this.listings.length > 0) {
        //console.log("mapping listing attributes");
        for (let property in this.listings[0]) {
          //console.log("property: ", property);
          options.push({
            value: property.toLowerCase(),
            label: property.toLowerCase(),
          });
        }
      }
      //console.log("options", options);
      this.options = options;
    },
    isLetter(c) {
      return c.toLowerCase() != c.toUpperCase();
    },
    parseTokens(tokenString) {
      let tokenized = "";
      let lastWord = -1;
      for (let i = 0; i < tokenString.length; i++) {
        if (
          i > 0 &&
          this.isLetter(tokenString[i]) &&
          tokenString[i] == tokenString[i].toUpperCase() &&
          tokenString[i - 1] == tokenString[i - 1].toLowerCase() &&
          tokenString[i - 1] != " " &&
          tokenString[i - 1] != "\n" &&
          tokenString[i - 1] != "/" &&
          tokenString[i - 1] != ";" &&
          tokenString[i - 1] != "("
        ) {
          tokenized += tokenString.substring(lastWord, i) + "; ";
          lastWord = i;
        }
        if (tokenized[i] == "\n") {
          tokenized += tokenString.substring(lastWord, i) + "; ";
          lastWord = i;
        }
      }
      // console.log("tokenized", tokenized);
      return tokenized;
    },
    parseSKUs(skuString) {
      let skus = [];
      let skuArray = skuString.split("\n");
      for (let sku of skuArray) {
        let skuParts = sku.split(";");
        skus.push({
          title: skuParts[0],
          external_id: skuParts[1],
          available: skuParts[2],
          package: skuParts[3],
          price: skuParts[4],
        });
      }
      return skus;
    },
    parseHTML(tokenString) {
      let tokenized = "";

      tokenized = tokenString.replaceAll("\n", "<br>");
      // console.log("html tokenized", tokenString, tokenized);
      return tokenized;
    },
    toLowerKeys(obj) {
      return Object.keys(obj).reduce((accumulator, key) => {
        accumulator[key.toLowerCase()] = obj[key];
        return accumulator;
      }, {});
    },
    async saveImage(imageUrl, vendorProductId) {
      // console.log("saving image", imageUrl, vendorProductId);
      if (!imageUrl) return;
      if (imageUrl.startsWith("https://static-dev.parcel.ag")) {
        // already uploaded, don't try to duplicate
        return;
      }
      let data = await dataUrlToFile(imageUrl, "logo");
      if (data) {
        this.$store.dispatch("uploadVendorInputImage", {
          productId: vendorProductId,
          file: data,
        });
      }
    },
    saveCsv() {
      // console.log("saveCsv - mapping ", this.mappings);
      this.listings = this.listings.map((listing) => {
        let newListing = this.toLowerKeys(listing);
        // console.log("properties", listing);
        for (const [key, { value }] of Object.entries(this.mappings)) {
          if (value) {
            // console.log("mapping", key, value);
            newListing[key] = newListing[value];
          }
        }
        // console.log("properties post mapping", newListing);
        return newListing;
      });
      // console.log("saving",this.listings);
      this.saveListings(this.listings);
    },

    async saveListings(listingsSet) {
      this.showReport = true;
      // console.log("saving listings", listingsSet);
      if (!listingsSet) return;
      this.importReport = "";
      await this.$store.dispatch("getVendorInputListings");
      let myListings = this.$store.state.vendorInputListings.listings;
      let successfulUpdates = 0;

      for (const row of listingsSet) {
        // console.log("processing a row", row);
        // lets limit the object to only valid keys
        let newListing = _.pick(row, Object.keys(this.productAttributes));
        // console.log(
        //   "newListing",
        //   newListing,
        //   Object.keys(this.productAttributes),
        //   row
        // );
        // if (newListing.cost)
        //   newListing.cost = newListing.cost.replace(/[^0-9.-]+/g, "");
        if (newListing.category?.length == 0) newListing.category = "Input"; // assume we are importing input products
        if (newListing.pests)
          newListing.pests = this.parseTokens(newListing.pests);
        if (newListing.crops)
          newListing.crops = this.parseTokens(newListing.crops);
        if (newListing.skus) newListing.skus = this.parseSKUs(newListing.skus);
        if (newListing.details)
          newListing.details = this.parseHTML(newListing.details);
        if (newListing.active && newListing.active.toLowerCase() == "false") {
          newListing.active = false;
        } else {
          newListing.active = true;
        }
        // console.log("newListing", newListing);
        let insert = false;
        let currentListing = null;
        // check if listing exists already - must have unique serial number or name
        // console.log(
        //   "checking for existing listing",
        //   myListings.filter(
        //     (listing) => listing.epa_number == newListing.epa_number,
        //   ),
        // );
        if (
          myListings.filter(
            (listing) => listing.epa_number == newListing.epa_number,
          ).length == 1
        ) {
          // console.log("found a match", newListing.epa_number);
          currentListing = myListings.filter(
            (listing) => listing.epa_number == newListing.epa_number,
          )[0];
          insert = false;
        } else if (
          myListings.filter((listing) => listing.title == newListing.title)
            .length == 1
        ) {
          currentListing = myListings.filter(
            (listing) => listing.title == newListing.title,
          )[0];
          insert = false;
        } else {
          currentListing = {};
          insert = true;
        }
        // no match found
        // console.log(
        //   "currentListing was not found",
        //   insert,
        //   myListings,
        //   currentListing,
        //   newListing,
        // );
        if (insert) {
          if (this.addRecords) {
            //insert a new listing
            // console.log("inserting new listing", newListing.title, row);
            this.importReport +=
              "Adding new listing " + newListing.title + "<br>";
            successfulUpdates++;
            let listing = await this.$store.dispatch(
              "createVendorInputListing",
              newListing,
            );
            // console.log("inserted listing", listing);
            // TODO: save image
            this.saveImage(newListing.image_url, listing.id);
            //
            for (let sku of newListing.skus) {
              if (sku.available && sku.available.toLowerCase() == "false") {
                sku.available = false;
              } else {
                sku.available = true;
              }
              if (sku.price) sku.price = parseFloat(sku.price);
              if (sku.price && isNaN(sku.price)) sku.price = 0;
              // console.log("inserting sku", listing.id, sku);
              await this.$store.dispatch("createVendorInputUnit", {
                listing: listing.id,
                unit: {
                  title: sku.title,
                  package: sku.package,
                  price: sku.price,
                  external_id: sku.external_id,
                  available: sku.available,
                },
              });
            }
          } else
            this.importReport +=
              "No match found for " + newListing.title + "<br>";
        } else if (currentListing && this.updateRecords) {
          // console.log("update Listing", currentListing, newListing);
          newListing = _.merge(currentListing, newListing);
          successfulUpdates++;

          await this.$store.dispatch("updateVendorInputListing", newListing);
          this.saveImage(newListing.image_url, newListing.id);
          let skus = this.$store.getters.getVendorInputListingUnitsByListingId(
            currentListing.id,
          );
          for (let sku of newListing.skus) {
            if (sku.available && sku.available.toLowerCase() == "false") {
              sku.available = false;
            } else {
              sku.available = true;
            }
            if (sku.price) sku.price = parseFloat(sku.price);
            if (sku.price && isNaN(sku.price)) sku.price = 0;
            // console.log("checking for existing sku", skus, sku);
            if (skus?.find((s) => s.title == sku.title)) {
              let currentSku = skus?.find((s) => s.title == sku.title);
              // console.log("updating sku", sku, currentSku);
              sku.id = currentSku.id;
              await this.$store.dispatch("updateVendorInputUnit", {
                listing: currentListing.id,
                unit: { id: currentSku.id, available: sku.available },
              });
            } else {
              // console.log("inserting sku", currentListing.id, sku);
              await this.$store.dispatch("createVendorInputUnit", {
                listing: currentListing.id,
                unit: {
                  title: sku.title,
                  package: sku.package,
                  price: sku.price,
                  external_id: sku.external_id,
                  available: sku.available,
                },
              });
            }
          }

          // console.log("updated listing");
        }
      }
      // console.log("import complete");
      this.importReport += "Updated " + successfulUpdates + " listings";
      this.showReport = true;
    },
  },
};
</script>
