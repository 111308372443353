<template>
  <div class="mt-6 overflow-hidden bg-white p-4 shadow sm:rounded-md">
    <form @submit.prevent="formatAsParcelGeoJSON" id="import">
      <div class="flex">
        <file-select
          buttonText="Step 1: Please select a shapefile (.shp or .zip)"
          @input="setShape"
          accept=".shp,.zip"
        />
        <template v-if="shape != null"
          ><check-circle-icon class="h-8 w-8 fill-green-500"
        /></template>
      </div>
      <div class="flex">
        <file-select
          buttonText="Step 2: Please select the shapefile's properties (.dbf)"
          @input="setDbf"
          accept=".dbf"
          :disabled="shape == null"
        />
        <template v-if="dbf != null"
          ><check-circle-icon class="h-8 w-8 fill-green-500"
        /></template>
      </div>
      <div class="flex">
        <base-button
          @click="uploadShapefile"
          buttonText="Step 3: Upload the files"
          :disabled="shape == null || dbf == null"
          loading="loading"
        />
        <template v-if="uploaded"
          ><check-circle-icon class="h-8 w-8 fill-green-500"
        /></template>
      </div>
      <div v-if="uploaded">
        <p class="mb-4 mt-4 block text-sm font-bold text-twilight-700">
          Step 4: Map the attributes
        </p>
        <map-attributes
          :attributes="fieldAttributes"
          :options="options"
          v-model="mappings"
        />
        <div class="mt-4">
          <submit-button
            formId="import"
            buttonText="Step 5: Save the fields"
            :disabled="!shape || !dbf"
          />
        </div>
      </div>
    </form>
  </div>
  <modal :show="showReport" title="Import Report">
    <div v-if="importReport">
      <div v-html="importReport" class="prose"></div>
      <base-button
        @click="showReport = false"
        buttonText="Close"
        :disabled="!uploaded"
      />
    </div>
    <div v-else>Please wait, Saving data...</div>
  </modal>
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/solid";
import FileSelect from "@/components/form/FileSelect.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import MapAttributes from "@/components/import/MapAttributes.vue";
import Modal from "@/components/modals/PopupModal.vue";
import gjv from "geojson-validation";
import _ from "lodash";
import JSZip from "jszip";
export default {
  components: {
    CheckCircleIcon,
    FileSelect,
    BaseButton,
    SubmitButton,
    MapAttributes,
    Modal,
  },
  props: {
    fieldAttributes: { type: Object },
  },
  data() {
    return {
      shape: null,
      dbf: null,
      uploaded: false,
      features: [],
      options: [],
      mappings: null,
      addRecords: true,
      updateRecords: true,
      importReport: "",
      showReport: false,
    };
  },
  computed: {},
  methods: {
    validatePolygon(coordinates) {
      //console.log("validate geometry",coordinates);
      for (let coordinate of coordinates) {
        for (let point of coordinate) {
          let lat = point[1];
          let lng = point[0];
          //console.log("validate geometry coordinate",lat,lng);
          if (lat > 90 || lat < -90 || lng > 180 || lng < -180) {
            // console.log("invalid geometry, coordinate out of range");
            return false;
          }
        }
      }
      return true;
    },
    handleZipFile(f) {
      let _this = this;
      JSZip.loadAsync(f) // 1) read the Blob
        .then(
          function (zip) {
            zip.forEach(function (relativePath) {
              // 2) read each file
              zip.files[relativePath].async("blob").then(function (fileData) {
                // 3) save fileData as shp or dbf
                switch (relativePath.split(".").pop()) {
                  case "shp":
                    _this.setShape(new File([fileData], relativePath));
                    break;
                  case "dbf":
                    _this.setDbf(new File([fileData], relativePath));
                    break;
                }
              });
            });
          },
          function (e) {
            console.log("error", e);
          },
        );
    },
    setShape(file) {
      // console.log("setShape", file);
      if (file.type == "application/zip") {
        this.handleZipFile(file);
      } else {
        this.shape = file;
      }
    },
    setDbf(file) {
      // console.log("setDbf", file);
      this.dbf = file;
    },
    uploadShapefile() {
      let features = [];
      let componentHandle = this;
      // console.log("uploadShapefile");
      (async () => {
        const shapefileContentStream = await this.shape.stream();
        const dbffileContentStream = await this.dbf.stream();
        var shapefile = require("shapefile");
        await shapefile
          .open(shapefileContentStream, dbffileContentStream)
          .then((source) => {
            source.read().then(function log(result) {
              if (result.done) {
                componentHandle.features = features;
                componentHandle.setMappingOptions();
                componentHandle.uploaded = true;
                return;
              }
              // console.log("result", result);
              features.push(result.value);
              //console.log("stream result", result.value);
              return source.read().then(log);
            });
            // console.log("source", source);
          })

          .catch((error) => console.error("stream error", error.stack));
      })();
    },
    setMappingOptions() {
      // grab first feature in features and use it to get the attributes
      let options = [];
      if (this.features.length > 0) {
        //console.log("mapping feature attributes");
        for (let property in this.features[0].properties) {
          //console.log("property: ", property);
          options.push({
            value: property.toLowerCase(),
            label: property.toLowerCase(),
          });
        }
      }
      //console.log("options", options);
      this.options = options;
    },
    toLowerKeys(obj) {
      return Object.keys(obj).reduce((accumulator, key) => {
        accumulator[key.toLowerCase()] = obj[key];
        return accumulator;
      }, {});
    },
    formatAsParcelGeoJSON() {
      for (let feature of this.features) {
        feature.properties = this.toLowerKeys(feature.properties);
        //console.log("properties", feature.properties);
        for (const [key, { value }] of Object.entries(this.mappings)) {
          if (value) {
            //console.log("mapping", key, value);
            feature.properties[key] = feature.properties[value];
          }
        }
        let parcelProperties = {};
        for (const [key, value] of Object.entries(feature.properties)) {
          switch (key) {
            case "name":
            case "farm":
            case "acres":
            case "crop":
              //console.log("primary", key, value);
              parcelProperties[key] = value;
              break;
            default: {
              if (!parcelProperties.details) parcelProperties.details = {};
              //console.log("details", parcelProperties, key, value);
              parcelProperties.details[key] = value;
            }
          }
        }
        if (!parcelProperties.name || parcelProperties.name == null)
          parcelProperties.name = "<unknown name>";
        //console.log("parcelProperties", parcelProperties);
        feature.properties = parcelProperties;

        //console.log("properties post mapping", feature.properties.name, feature.geometry.type);
        //if (feature.geometry.type == 'MultiPolygon') console.log("multipolygon", feature.geometry);
        // for (let polygon of feature.geometry.coordinates) {
        //   //console.log("polygon", polygon);

        //   for (let ring of polygon) {
        //     console.log("ring", ring);
        //     // for (let coordinate of ring) {
        //     //   console.log("coordinate", coordinate);
        //     // }
        //   }
        // }
      }
      this.saveFields(this.features);
    },

    async saveFields(fieldsSet) {
      // console.log("saving fields", fieldsSet);
      this.showReport = true;
      if (!fieldsSet) return;
      this.importReport = "";
      let myFields = this.$store.state.fields.fields;
      let successfulUpdates = 0;
      for (const row of fieldsSet) {
        // console.log("processing a row", row.properties.name);
        let insert = false;
        let currentField = null;
        // Validate geometry
        if (row.type != "Feature") {
          this.importReport += `${row.properties.name} - invalid geometry\n`;
          continue;
        }
        if (!gjv.isFeature(row)) {
          // console.log("invalid geometry");
          this.importReport +=
            "Skipping field " + row.properties.name + " invalid geometry<br>";
          continue; // move on to the next row
        }
        if (
          row.geometry?.type !== "Polygon" &&
          row.geometry?.type !== "MultiPolygon"
        ) {
          // console.log("invalid geometry, unsupported format");
          this.importReport +=
            "Skipping field " +
            row.properties.name +
            " unsupported geometry format " +
            row.geometry?.type +
            "<br>";
          continue; // move to next field
        }
        if (row.geometry?.type == "Polygon") {
          if (!this.validatePolygon(row.geometry.coordinates)) {
            // console.log("invalid geometry, coordinate out of range");
            this.importReport +=
              "Skipping field " + row.properties.name + " invalid geometry<br>";
            continue; // move to next field
          }
        }
        if (row.geometry?.type == "MultiPolygon") {
          for (let polygon of row.geometry.coordinates) {
            // console.log("evaluate multipolygon", polygon, row);
            if (!this.validatePolygon(polygon)) {
              // console.log("invalid geometry, coordinate out of range");
              this.importReport +=
                "Skipping field " +
                row.properties.name +
                " invalid geometry<br>";
              continue; // move to next field
            }
          }
        }
        // check if field exists already
        currentField = {};
        insert = true;
        if (
          myFields.filter(
            (field) => field.properties.name == row.properties.name,
          ).length >= 1
        ) {
          if (
            myFields.filter(
              (field) =>
                field.properties.name == row.properties.name &&
                field.properties.farm == row.properties.farm,
            ).length >= 1
          ) {
            currentField = myFields.filter(
              (field) =>
                field.properties.name == row.properties.name &&
                field.properties.farm == row.properties.farm,
            )[0];
            insert = false;
          } else {
            // no match found for farm and field, but there is a field match.
            currentField = myFields.filter(
              (field) => field.properties.name == row.properties.name,
            )[0];
            insert = false;
          }
        }
        // no match found
        if (insert) {
          if (this.addRecords) {
            //insert a new field
            // console.log(
            //   "inserting new field",
            //   row.properties.name,
            //   row,
            //   myFields.filter(
            //     (field) =>
            //       field.properties.name == row.properties.name &&
            //       field.properties.farm == row.properties.farm
            //   ).length,
            //   insert
            // );
            this.importReport +=
              "Adding new field " + row.properties.name + "<br>";
            successfulUpdates++;
            await this.$store.dispatch("createField", row);
          } else
            this.importReport +=
              "No match found for " + row.properties.name + "<br>";
        } else if (currentField && this.updateRecords) {
          if (row.geometry) currentField.geometry = null; // update geometry, don't add them together.
          let newField = _.merge(currentField, row);
          successfulUpdates++;
          // console.log("updating existing field", newField, currentField, row);
          await this.$store.dispatch("updateField", newField);
          // console.log("updated field");
        }
      }
      // console.log("import complete");
      this.importReport +=
        "Successfully updated " + successfulUpdates + " fields";
      this.showReport = true;
    },
  },
};
</script>
