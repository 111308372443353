<template>
  <div class="mt-6 overflow-hidden bg-white p-4 shadow sm:rounded-md">
    <form @submit.prevent="saveCsv" id="import">
      <div class="flex">
        <file-select
          buttonText="Step 1: Please select a file to import (.csv)"
          @input="setFile"
          accept=".csv"
        />
        <template v-if="csv != null"
          ><check-circle-icon class="h-8 w-8 fill-green-500"
        /></template>
      </div>
      <div class="flex">
        <base-button
          @click="uploadCsvfile"
          buttonText="Step 2: Upload the file"
          :disabled="csv == null"
          loading="loading"
        />
        <template v-if="uploaded"
          ><check-circle-icon class="h-8 w-8 fill-green-500"
        /></template>
      </div>
      <div v-if="uploaded">
        <p class="mb-4 mt-4 block text-sm font-bold text-twilight-700">
          Step 3: Map the attributes
        </p>
        <map-attributes
          :attributes="orderAttributes"
          :options="options"
          v-model="mappings"
        />
        <div class="mt-4">
          <submit-button
            formId="import"
            buttonText="Step 4: Import the orders"
            :disabled="!csv"
          />
        </div>
      </div>
    </form>
  </div>
  <modal :show="showReport" title="Import Report">
    <div v-if="importReport">
      <div v-html="importReport" class="prose"></div>
      <base-button
        @click="showReport = false"
        buttonText="Close"
        :disabled="!uploaded"
      />
    </div>
    <div v-else>Loading data...</div>
  </modal>
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/solid";
import FileSelect from "@/components/form/FileSelect.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import MapAttributes from "@/components/import/MapAttributes.vue";
import Modal from "@/components/modals/PopupModal.vue";
import Papa from "papaparse";
// import _ from "lodash";
export default {
  components: {
    CheckCircleIcon,
    FileSelect,
    BaseButton,
    SubmitButton,
    MapAttributes,
    Modal,
  },
  props: {
    orderAttributes: { type: Object },
  },
  data() {
    return {
      csv: null,
      uploaded: false,
      orders: [],
      options: [],
      mappings: null,
      addRecords: true,
      updateRecords: true,
      importReport: "",
      showReport: false,
    };
  },
  computed: {},
  methods: {
    setFile(file) {
      this.csv = file;
    },
    async uploadCsvfile() {
      let componentHandle = this;
      let orders = [];
      let header = [];
      // console.log("uploadCsvfile");

      await Papa.parse(this.csv, {
        complete: function (results) {
          // console.log("parse done", results);
          // console.log("csvArray", results);
          // grab the first column to get column names
          for (let column of results.data[0]) {
            header.push(column);
          }
          // console.log("header", header);
          for (const [i, row] of results.data.entries()) {
            if (i > 0) {
              //skip the header
              let order = {};
              for (let i = 0; i < header.length; i++) {
                order[header[i]] = row[i];
              }
              orders.push(order);
            }
          }
          // console.log("orders uploaded", orders);
          componentHandle.orders = orders;
          componentHandle.setMappingOptions();
          componentHandle.uploaded = true;
        },
      });
    },
    setMappingOptions() {
      // grab first order in orders and use it to get the attributes
      let options = [];
      if (this.orders.length > 0) {
        //console.log("mapping order attributes");
        for (let property in this.orders[0]) {
          //console.log("property: ", property);
          options.push({
            value: property.toLowerCase(),
            label: property.toLowerCase(),
          });
        }
      }
      //console.log("options", options);
      this.options = options;
    },
    toLowerKeys(obj) {
      return Object.keys(obj).reduce((accumulator, key) => {
        accumulator[key.toLowerCase()] = obj[key];
        return accumulator;
      }, {});
    },
    saveCsv() {
      //console.log("saveCsv - mapping ", this.mappings);
      this.orders = this.orders.map((order) => {
        let neworder = this.toLowerKeys(order);
        // console.log("properties", order);
        for (const [key, { value }] of Object.entries(this.mappings)) {
          if (value) {
            // console.log("mapping", key, value);
            neworder[key] = neworder[value];
          }
        }
        // console.log("properties post mapping", neworder);
        return neworder;
      });
      // console.log("saving",this.orders);
      this.saveOrders(this.orders);
    },

    async saveOrders(ordersSet) {
      let mycustomers = this.$store.state.customers.customers;
      let myorders = this.$store.state.growerCropOrders.growerCropOrders;
      let myFields = this.$store.state.fields.fields;
      let myAllocations = this.$store.state.growerAllocations.growerAllocations;
      if (!ordersSet) return;
      this.showReport = true;
      this.importReport = "";
      // console.log("myorders", myorders, mycustomers);
      let successfulUpdates = 0;

      for (const row of ordersSet) {
        // first see if the customer exists, if not add them first
        let customer = mycustomers.find(
          (customer) => customer.name == row.buyer,
        );

        if (!customer) {
          // console.log("customer does not exist", row.buyer);
          customer = await this.$store.dispatch("createCustomer", {
            placeholder_name: row.buyer_details?.trim(),
            placeholder_contact_email: row.primary_contact?.trim(),
            // placeholder_contact_phone: this.currentCustomer.contact_phone,
            seller_confirmed: false,
          });
          mycustomers = this.$store.state.customers.customers;
          this.importReport += "Adding new customer " + row.buyer + "<br>";
          successfulUpdates++;
        }
        // console.log("customer", customer, row.contract_number, mycustomers);
        // then see if the order exists and we are just allocating to it. If not add it
        let order = myorders.find(
          (order) =>
            order.details?.contract_number == row.contract_number &&
            order.status == "In Progress",
        );
        if (!order) {
          // console.log("order does not exist", row.contract_number, customer.id);
          let orderId = await this.$store.dispatch("createGrowerCropOrder", {
            org: customer.id,
            status: "In Progress",
            terms: row.terms,
            quantity: row.order_quantity ? Number(row.order_quantity) : 0,
            units:
              row.order_units.charAt(0).toUpperCase() +
              row.order_units.slice(1).toLowerCase(),
            seller: this.$store.getters.getCurrentOrg?.id,
            estimated_price: row.price ? Number(row.price) : 0,
            details: {
              contract_number: row.contract_number,
            },
          });
          this.importReport +=
            "Adding new order " + row.contract_number + "<br>";
          successfulUpdates++;
          myorders = this.$store.state.growerCropOrders.growerCropOrders;
          order = this.$store.getters.getGrowerCropOrderById(orderId);
          // console.log("new orderId", orderId, order, myorders);
        } else {
          // the order exists, update it
          let updatedOrder = {
            quantity: row.order_quantity ? Number(row.order_quantity) : 0,
            status: "In Progress",
            field: null,
            listing: null,
            estimated_price: row.price ? Number(row.price) : 0,
            units:
              row.order_units.charAt(0).toUpperCase() +
              row.order_units.slice(1).toLowerCase(),
            terms: row.terms,
            details: {
              contract_number: row.contract_number,
            },
            org: customer.id,
            id: order.id,
          };
          successfulUpdates++;
          this.importReport += "Updating order " + row.contract_number + "<br>";
          // console.log("updated order", updatedOrder, order);
          await this.$store.dispatch("updateGrowerCropOrder", updatedOrder);

          myorders = this.$store.state.growerCropOrders.growerCropOrders;
          // console.log("updated orderId", order.id);
          order = this.$store.getters.getGrowerCropOrderById(order.id);
        }
        // console.log("order", order);
        // then allocate the field to the order.
        // find the field
        let fieldToAllocate = myFields.find(
          (field) =>
            field.properties.name == row.name &&
            field.properties.farm == row.farm,
        );

        if (!fieldToAllocate) {
          this.importReport +=
            "Can't find field " +
            row.farm +
            " " +
            row.name +
            ", skipping allocation <br>";
          // console.log(
          //   "Can't find field " + row.farm + " " + row.name,
          //   myFields,
          // );
          continue;
        }
        // allocate field to order
        // console.log("allocation", fieldToAllocate, order);
        // check if we already imported this allocation.
        let thisAllocation = myAllocations.find(
          (allocation) =>
            allocation.crop == fieldToAllocate?.properties?.current_crop?.id &&
            allocation.order == order.id,
        );
        // console.log("thisAllocation", thisAllocation);
        if (thisAllocation) {
          //update acres
          await this.$store.dispatch("updateGrowerAllocation", {
            id: thisAllocation.id,
            acres: row.allocated_acres ? Number(row.allocated_acres) : 0,
            crop: fieldToAllocate?.properties?.current_crop?.id,
            order: order.id,
          });
          successfulUpdates++;
          this.importReport +=
            "Updating allocation of " +
            row.allocated_acres +
            " acres from " +
            row.farm +
            " " +
            row.name +
            " to order #" +
            order.id +
            " <br>";
        } else {
          // create new allocation for this crop on this order
          await this.$store.dispatch("createGrowerAllocation", {
            crop: fieldToAllocate?.properties?.current_crop?.id,
            order: order.id,
            acres: row.allocated_acres ? Number(row.allocated_acres) : 0,
          });
          successfulUpdates++;
          this.importReport +=
            "Inserting allocation of " +
            row.allocated_acres +
            " acres from " +
            row.farm +
            " " +
            row.name +
            "to order #" +
            order.id +
            " <br>";
        }
      }
      // console.log("import complete");
      this.importReport += "Updated " + successfulUpdates + " items";
      this.showReport = true;
    },
  },
};
</script>
