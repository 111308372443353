<template>
  <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6">
    <div
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Contract Details
    </div>
    <text-input
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Contract Name"
      inputHtmlType="text"
      :required="true"
      v-model="currentContract.contract_name"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.contract_name"
    />
    <text-area
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Contract Description"
      inputHtmlType="text"
      v-model="currentContract.contract_description"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.contract_description"
    />
    <text-input
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Program Name"
      inputHtmlType="text"
      v-model="currentContract.program_name"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.program_name"
    />
    <number-input
      v-if="currentContract"
      :wideItem="true"
      :required="true"
      inputTitle="Program Year"
      inputHtmlType="text"
      v-model="currentContract.program_year"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.program_year"
    />
    <date-input
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Payment Date"
      inputHtmlType="text"
      v-model="currentContract.payment_date"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.payment_date"
    />
    <currency-input
      v-if="currentContract?.contract_partner_payment"
      :wideItem="true"
      inputTitle="Contract Partner Payment"
      inputHtmlType="text"
      v-model="currentContract.contract_partner_payment"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.contract_partner_payment"
    />
    <text-input
      v-if="currentContract?.contract_partner_payment_units"
      :wideItem="true"
      inputTitle="Contract Partner Payment Units"
      inputHtmlType="text"
      v-model="currentContract.contract_partner_payment_units"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.contract_partner_payment_units"
    />
    <currency-input
      v-if="currentContract?.contract_total_value"
      :wideItem="true"
      inputTitle="Contract Total Value"
      inputHtmlType="text"
      v-model="currentContract.contract_total_value"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.contract_total_value"
    />
    <!-- Alternate display of crop year backdating based on org type -->
    <number-input
      v-if="currentContract && currentOrgType === 'buyer'"
      :wideItem="true"
      inputTitle="Prior Years Data Requested"
      inputHtmlType="text"
      :required="true"
      v-model="currentContract.additional_prior_year_records"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.additional_prior_year_records"
    />
    <number-input
      v-else-if="currentContract && currentOrgType === 'grower'"
      :wideItem="true"
      inputTitle="Starting Year for Shared Data"
      :help-text="'Sharing data from the displayed year through the current year is required for this offer.'"
      inputHtmlType="text"
      :displayOnly="true"
      :displayOnlyValue="
        getDateYearAsNumber(currentContract.deliver_by) -
        currentContract.additional_prior_year_records
      "
    />
    <select-search
      v-if="currentContract?.contract_status"
      :wide-item="true"
      input-title="Status"
      v-model="currentContract.status"
      :selectOptions="statusOptions"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.status"
    />
    <checkbox-input
      v-if="currentContract && !readOnly"
      :wide-item="true"
      input-title="Invoice Sent"
      v-model="currentContract.invoice_sent"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.invoice_sent"
    />
    <checkbox-input
      v-if="currentContract && !readOnly"
      :wide-item="true"
      input-title="Invoice Paid"
      v-model="currentContract.invoice_paid"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.invoice_paid"
    />
    <checkbox-input
      v-if="currentContract && !readOnly"
      :wide-item="true"
      input-title="Growers Paid"
      v-model="currentContract.growers_paid"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.growers_paid"
    />
    <checkbox-input
      v-if="currentContract && !readOnly"
      :wide-item="true"
      input-title="Affiliates Paid"
      v-model="currentContract.affiliates_paid"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.affiliates_paid"
    />
    <div
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Crop
    </div>
    <select-search
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Crop"
      inputHtmlType="text"
      :required="true"
      v-model="currentContract.crop"
      :selectOptions="cropOptions"
      class="ml-1 resize-none whitespace-pre-wrap sm:col-span-3"
      classProp="resize-none"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.crop"
    />
    <select-search
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Quantity Units"
      inputHtmlType="text"
      v-model="currentContract.quantity_units"
      :required="true"
      :select-options="unitOptions"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.quantity_units"
    />
    <number-input
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Quantity"
      inputHtmlType="text"
      :required="true"
      v-model="currentContract.quantity"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.quantity"
    />
    <text-input
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Deliver To"
      inputHtmlType="text"
      v-model="currentContract.elevator"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.elevator"
    />
    <date-input
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Deliver By Date"
      :required="true"
      inputHtmlType="text"
      v-model="currentContract.deliver_by"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.deliver_by"
    />
    <text-area
      v-if="currentContract"
      :wideItem="true"
      inputTitle="Delivery Location(s)"
      :required="false"
      inputHtmlType="text"
      v-model="currentContract.delivery_location"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.delivery_location"
    />
    <div
      v-if="currentContract && currentOrgType === 'buyer'"
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Client Information (Internal Use Only, Not shared with growers)
    </div>
    <text-input
      v-if="currentContract && currentOrgType === 'buyer'"
      :wideItem="true"
      inputTitle="Client Name"
      inputHtmlType="text"
      v-model="currentContract.client_name"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.client_name"
    />
    <email-input
      v-if="currentContract && currentOrgType === 'buyer'"
      :wideItem="true"
      inputTitle="Client Email"
      inputHtmlType="text"
      v-model="currentContract.client_email"
      :displayOnly="readOnly"
      :displayOnlyValue="currentContract.client_email"
    />
  </div>
</template>

<script>
import TextInput from "@/components/form/TextInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import EmailInput from "@/components/form/EmailInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import CurrencyInput from "@/components/form/CurrencyInput.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import DateInput from "@/components/form/DateInput.vue";
import { harvestUnits } from "@/components/composables/convertUnits";
import { getDateYearAsNumber } from "@/components/composables/dateUtils";

let cropList = require("@/assets/croplist.js");

export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: { type: Object },
    readOnly: { type: Boolean, default: false },
  },
  components: {
    TextInput,
    CurrencyInput,
    TextArea,
    NumberInput,
    EmailInput,
    SelectSearch,
    CheckboxInput,
    DateInput,
  },
  data() {
    return {
      listOfCropOptionObjects: cropList,
      currentContract: this.modelValue,
    };
  },
  computed: {
    cropOptions() {
      let options = ["Any Crop"];
      for (let crop of this.listOfCropOptionObjects) {
        options.push(crop.name);
      }
      return options;
    },
    unitOptions() {
      let unitOptions = [{ label: "Acres", value: "acres" }];
      let cropUnit = harvestUnits(this.currentContract.crop);
      if (cropUnit) {
        unitOptions.push(cropUnit);
      }
      return unitOptions;
    },
    statusOptions() {
      return [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
        { label: "Completed", value: "completed" },
      ];
    },
    currentOrgType() {
      // returns "buyer", "vendor", "grower";
      return this.$store.getters.getCurrentOrgType;
    },
  },
  methods: {
    getDateYearAsNumber,
  },
  watch: {
    modelValue: {
      handler(value) {
        this.currentContract = value;
      },
      deep: true,
      immediate: true,
    },
    currentContract: {
      handler(value) {
        this.$emit("update:modelValue", value);
      },
      deep: true,
    },
  },
};
</script>
