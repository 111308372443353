export function getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  let monday = new Date(d.setDate(diff));
  monday.setHours(0, 0, 0, 0);
  return monday;
}

export function getDayofMonth(date, offset) {
  let d = new Date(date);
  d.setDate(d.getDate() + offset);
  return d.getDate();
}

// getUTCString(new Date(this.date + " " + this.time)
export function getUTCString(date) {
  var utc = new Date(date);
  utc.setMinutes(utc.getMinutes() - utc.getTimezoneOffset());

  // console.log(new Date(utc), new Date(utc).toISOString());
  return new Date(utc).toISOString();
}

export function getDateString(date) {
  var d = new Date(date);
  return d.toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function getDateYearAsNumber(date) {
  var d = new Date(date);
  return d.getFullYear();
}
export function getShortDateString(date) {
  return date ? new Date(date).toISOString().split("T")[0] : undefined;
}

// Time Zone adjusting functions
export function getLocalTimeFromUTC(date) {
  var local = new Date(date);
  local.setMinutes(local.getMinutes() + local.getTimezoneOffset());
  return local;
}

export function getUTCFromLocalTime(date) {
  var utc = new Date(date);
  utc.setMinutes(utc.getMinutes() - utc.getTimezoneOffset());
  return utc;
}

// Date formatting functions
export function getShortDisplayDate(date) {
  return new Date(date).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function getLongDisplayDate(date) {
  var d = new Date(date);
  return d.toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}
